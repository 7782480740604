import { ThreadEdgeSimple } from "@utility-types";
import { DropdownActionButton } from "components/design-system/FloatingUi/DropdownActionButton";
import { DropdownActionButtonGroup } from "components/design-system/FloatingUi/DropdownActionButtonGroup";
import { addDays, addHours, nextMonday, set } from "date-fns";
import useInboxThreadActions from "hooks/thread/useInboxThreadActions";
import useModalStore from "store/useModalStore";
import ThreadReminderCustomModal from "./ThreadReminderCustomModal";

enum ReminderOption {
  ONE_HOUR = "1_HOUR",
  THREE_HOURS = "3_HOURS",
  TOMORROW = "TOMORROW",
  NEXT_WEEK = "NEXT_WEEK",
  CUSTOM = "CUSTOM",
}

const setTimeTo9am = (date: Date) =>
  set(date, {
    hours: 9,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });

const reminderOptions = [
  { label: "In 1 hour", value: ReminderOption.ONE_HOUR },
  { label: "In 3 hours", value: ReminderOption.THREE_HOURS },
  { label: "Tomorrow at 9:00 AM", value: ReminderOption.TOMORROW },
  { label: "Monday at 9:00 AM", value: ReminderOption.NEXT_WEEK },
  { label: "Custom...", value: ReminderOption.CUSTOM },
];

const ThreadReminderMenu = ({
  setParentDropdownOpen,
  threadEdge,
}: {
  setParentDropdownOpen?: (open: boolean) => void;
  threadEdge: ThreadEdgeSimple;
}) => {
  const { openModal } = useModalStore(({ openModal }) => ({ openModal }));
  const { markThreadEdges } = useInboxThreadActions();

  const getRelativeTime = (reminder: ReminderOption) => {
    switch (reminder) {
      case ReminderOption.ONE_HOUR:
        return addHours(new Date(), 1).toISOString();
      case ReminderOption.THREE_HOURS:
        return addHours(new Date(), 3).toISOString();
      case ReminderOption.TOMORROW:
        return setTimeTo9am(addDays(new Date(), 1)).toISOString();
      case ReminderOption.NEXT_WEEK:
        return setTimeTo9am(nextMonday(new Date())).toISOString();
    }
  };

  const handleSelectReminder = (reminder: ReminderOption) => {
    setParentDropdownOpen?.(false);
    if (reminder === ReminderOption.CUSTOM) {
      return openModal(<ThreadReminderCustomModal threadEdge={threadEdge} />);
    }
    const remindAt = getRelativeTime(reminder);
    markThreadEdges({
      selection: { threadEdges: [threadEdge] },
      remindAt,
    });
  };

  return (
    <>
      <DropdownActionButtonGroup>
        <span className="flex items-center text-footnote-bold text-text-subtle h-32 px-16 select-none">
          Remind me
        </span>
        {reminderOptions.map(option => (
          <DropdownActionButton
            key={option.label}
            onClick={() => handleSelectReminder(option.value)}
          >
            {option.label}
          </DropdownActionButton>
        ))}
      </DropdownActionButtonGroup>
      <DropdownActionButtonGroup>
        <DropdownActionButton
          disabled={!threadEdge.remindAt}
          onClick={() => {
            setParentDropdownOpen?.(false);
            markThreadEdges({
              selection: { threadEdges: [threadEdge] },
              archived: false,
              remindAt: null,
            });
          }}
        >
          Remove reminder
        </DropdownActionButton>
      </DropdownActionButtonGroup>
    </>
  );
};

export default ThreadReminderMenu;
