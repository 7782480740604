import { Thread, ThreadPreview, ThreadSimple } from "@utility-types";

type ThreadChatType = "user" | "group" | null | undefined;

/**
 * @summary Returns the type of chat based on the node.
 */

const threadChatType = (
  node: Thread | ThreadSimple | ThreadPreview | undefined
): ThreadChatType => {
  if (!node || node.__typename === "ThreadPreview") return undefined;
  if (!node.isPersistentChat) return null;
  return node.recipients.edges[0]?.node.__typename === "User"
    ? "user"
    : "group";
};

export default threadChatType;
