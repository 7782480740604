import useFloatingElement from "components/design-system/FloatingUi/useFloatingElement";
import { Icon } from "components/design-system/icons";
import useElementSize from "hooks/useElementSize";
import tw from "utils/tw";

const SelectOption = ({
  children,
  value,
  className,
  isSelected,
  onChange,
}: {
  children: string;
  value: string;
  className?: string;
  isSelected: boolean;
  onChange: (value: string) => void;
}) => {
  return (
    <div
      // FIXME: role="option" must be used within a `select` element
      // role="option"
      aria-selected={isSelected}
      className={tw(
        "p-8 hover:bg-background-list-hover cursor-pointer flex gap-8",
        { "bg-background-list-hover": isSelected },
        className
      )}
      onClick={() => onChange(value)}
    >
      <span className="text-subhead whitespace-nowrap">{children}</span>
      {isSelected && (
        <Icon
          className="text-icon-primary-selected ml-auto"
          icon="Check"
          size={20}
        />
      )}
    </div>
  );
};

type Props = {
  onChange: (value: string) => void;
  value: string;
  className?: string;
  options: { value: string; label: string; className?: string }[];
};

export const Select = ({ onChange, value, className, options }: Props) => {
  const { anchorProps, floatingProps, isOpen, setIsOpen } = useFloatingElement({
    strategy: "fixed",
    placement: "bottom",
    openOnClick: true,
  });

  const [inputRef, { inlineSize: inputWidth }] =
    useElementSize<HTMLDivElement>();

  const floatingStyles =
    "style" in floatingProps && typeof floatingProps.style === "object"
      ? floatingProps.style
      : {};

  const handleChange = (value: string) => {
    onChange(value);
    setIsOpen(false);
  };

  const label = options.find(o => o.value === value)?.label;

  return (
    <>
      <div {...anchorProps}>
        <div
          tabIndex={0}
          ref={inputRef}
          className={tw(
            "flex pr-7 pl-10 py-7 md:py-3 border border-border-container rounded select-none cursor-pointer gap-8 text-subhead text-text-subtle",
            {
              "border-border-active": isOpen,
            },
            className
          )}
        >
          {label}

          <Icon
            icon={isOpen ? "DropUp" : "DropDown"}
            size="20"
            className="ml-auto text-icon-secondary"
          />
        </div>
      </div>
      {isOpen && (
        <div
          {...floatingProps}
          className="bg-background-body border-1 border-border-container shadow-level2 rounded-md -mt-6 overflow-y-auto max-h-160"
          style={{ minWidth: `${inputWidth}px`, ...floatingStyles }}
        >
          {options.map(option => (
            <SelectOption
              key={option.value}
              value={option.value}
              className={option.className}
              isSelected={option.value === value}
              onChange={handleChange}
            >
              {option.label}
            </SelectOption>
          ))}
        </div>
      )}
    </>
  );
};
