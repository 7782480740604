import { useCallback, useRef, useState } from "react";

import { sortBy, uniqBy } from "lodash-es";

import { User } from "@utility-types";
import { UserEdgeStatus } from "generated/graphql";
import {
  ContactFragment,
  useNativeContacts,
} from "hooks/native/useNativeContacts";
import useComponentMounted from "hooks/useComponentMounted";
import hashAddr from "utils/hashAddr";

import { useInstantSearch } from "components/views/search/hooks";

export type ContactUser = User & { addressID: string };

type ContactResult = {
  address: string;
  name: string;
  photoThumbnail?: string;
};

const contactMatches = (
  match: string,
  contact: ContactFragment
): ContactResult[] => {
  if (
    contact.name?.display?.toLocaleLowerCase()?.startsWith(match) ||
    contact.emails?.find(e => e.address?.toLocaleLowerCase()?.startsWith(match))
      ?.address
  ) {
    return (
      contact.emails
        ?.filter(e => e.address?.match(/@/))
        .map(e => ({
          address: e.address?.toLocaleLowerCase().trim() || "",
          name: contact.name?.display?.trim() || "",
          photoThumbnail: undefined,
        })) ?? []
    );
  }
  return [];
};

// https://github.com/gluegroups/glue-api/blob/a8ede1c322c989fdb11c383345504f95e0e7a593/rfcs/001-users-and-addresses.md#addresses
const contactToAddressRecipient = (
  contact: ContactResult
): Promise<ContactUser | undefined> =>
  hashAddr(contact.address)
    .then(addrHash => ({
      __typename: "User" as const,
      addressDomains: [],
      addressID: `adr_${addrHash}`,
      avatarURL: contact.photoThumbnail || "",
      bio: contact.name ? contact.address : "",
      id: contact.address,
      name: contact.name || contact.address,
      workspaceIDs: [],
    }))
    .catch(e => {
      console.warn(e);
      return undefined;
    });

type Props = Parameters<typeof useInstantSearch>[0] & {
  excludeIDs?: string[];
  userEdgeStatus?: UserEdgeStatus[];
};

export const useFetchRecipients = ({
  exclude,
  excludeIDs,
  resultsOrder,
  reverse,
  userEdgeStatus = [],
}: Props) => {
  const [contacts, setContacts] = useState<ContactUser[]>();
  const { getContacts, getPermission, permission } = useNativeContacts();
  const { search: instantSearch, searchResults } = useInstantSearch({
    exclude,
    resultsOrder,
    reverse,
  });

  const isMounted = useComponentMounted();
  const cachedContacts = useRef<ContactFragment[]>();

  const getContactsWithCache = useCallback(() => {
    if (!window.crypto?.subtle) {
      // needed for address hashing, not available
      // in insecure native Safari development envs
      return Promise.resolve([]);
    }

    if (cachedContacts.current) {
      return Promise.resolve(cachedContacts.current);
    }

    if (!permission) {
      return Promise.resolve([]);
    }

    return getContacts().then(contacts => {
      cachedContacts.current = contacts;
      return contacts;
    });
  }, [cachedContacts, permission, getContacts]);

  const searchContacts = useCallback(
    (match: string) =>
      getContactsWithCache().then(contacts => {
        const matchLower = match.toLocaleLowerCase();
        let results = contacts.flatMap(contact =>
          contactMatches(matchLower, contact)
        );

        results = sortBy(
          uniqBy(results, r => r.address),
          r =>
            r.photoThumbnail
              ? r.name
              : r.name
                ? `zz${r.name}`
                : `zzz${r.address}`
        );

        return Promise.all(results.slice(0, 10).map(contactToAddressRecipient));
      }),
    [getContactsWithCache]
  );

  const excludeIDsRef = useRef(excludeIDs);
  excludeIDsRef.current = excludeIDs;
  const userEdgeStatusRef = useRef(userEdgeStatus);
  userEdgeStatusRef.current = userEdgeStatus;

  const resultsOrderRef = useRef(resultsOrder);
  resultsOrderRef.current = resultsOrder;

  const fetchRecipients = useCallback(
    async (search: string) => {
      const contacts = (await searchContacts(search)).filter(
        (r): r is ContactUser => !!r?.id
      );

      if (!isMounted.current) return;

      setContacts(contacts);

      const contactAddressIDs = contacts.map(r => r.addressID);
      instantSearch({
        contactAddressIDs: contactAddressIDs.length
          ? contactAddressIDs
          : undefined,
        excludeGroupsIDs: excludeIDsRef.current?.filter(id =>
          id.startsWith("grp")
        ),
        excludeThreadsIDs: excludeIDsRef.current?.filter(id =>
          id.startsWith("thr")
        ),
        excludeUsersIDs: excludeIDsRef.current?.filter(id =>
          id.startsWith("usr")
        ),
        groups: resultsOrderRef.current?.includes("groups") || false,
        match: search,
        threads: false,
        userEdgeStatus: userEdgeStatusRef.current.length
          ? userEdgeStatusRef.current
          : undefined,
      });
    },
    [searchContacts, isMounted, instantSearch]
  );

  return {
    contacts,
    contactsPermission: permission,
    fetchRecipients,
    getContactsPermission: getPermission,
    searchResults,
  };
};
