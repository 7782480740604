import { Directory, Filesystem } from "@capacitor/filesystem";
import { Share } from "@capacitor/share";
import axios from "axios";

import useProgressStore from "store/useProgressStore";
import { isNativeMobile } from "utils/platform";

const convertBlobToBase64 = (blob: Blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });

// get Blob not Bob 🥊
async function getBlob(
  input: string,
  abortSignal: AbortSignal,
  onProgress?: (progress: number) => void
) {
  let lastProgress = 0;
  try {
    // to indicate the download has been initiated we set the progress to 10.
    onProgress?.(10);
    lastProgress = 10;
    return await axios.get(input, {
      onDownloadProgress: progressEvent => {
        const loaded = progressEvent.loaded * 100;
        const total = Number.parseInt(progressEvent.total);
        const progress = Math.round(loaded / total);

        // prevents the modal from auto closing
        // we are setting this to 100 once we saved the file
        if (progress >= 99) return;

        if (progress > lastProgress) {
          onProgress?.(progress);
          lastProgress = progress;
        }
      },
      responseType: "blob",
      signal: abortSignal,
    });
  } catch (error) {
    console.error(error);
    return null;
  }
}

let unsubscribe: undefined | (() => void);

export default async function shareFileNativeMobile({
  abortSignal,
  dialogTitle,
  fileName,
  onError,
  showProgress,
  title,
  url,
}: {
  abortSignal: AbortSignal;
  dialogTitle: string;
  fileName: string;
  onError?: () => void;
  showProgress?: () => void;
  title?: string;
  url: string;
}) {
  if (!isNativeMobile()) return;

  let isShowingProgress = false;

  const timeout = setTimeout(() => {
    if (abortSignal.aborted) return;

    isShowingProgress = true;

    showProgress?.();
  }, 150);

  const response = await getBlob(url, abortSignal, value =>
    useProgressStore.setState({ value })
  );

  if (response === null) return onError?.();

  const base64Data = await convertBlobToBase64(response?.data);

  if (typeof base64Data !== "string") return onError?.();

  if (abortSignal.aborted) return;

  const savedFile = await Filesystem.writeFile({
    data: base64Data,
    directory: Directory.Cache,
    path: fileName,
  }).catch(e => {
    onError?.();
    console.warn("[Share] - ", e);
    return null;
  });

  if (!savedFile) return onError?.();

  clearTimeout(timeout);

  if (isShowingProgress) {
    unsubscribe = useProgressStore.subscribe(({ value }) => {
      // waiting for the progress to be reset before we open the share sheet
      if (value !== 0) return;

      Share.share({
        dialogTitle,
        title: title || fileName,
        url: savedFile.uri,
      }).catch(e => {
        e.errorMessage !== "Share canceled" && onError?.();
        console.warn("[Share] - ", e);
      });

      unsubscribe?.();
      unsubscribe = undefined;
    });

    // at this point the file has been saved without errors and the progress modal can auto close
    useProgressStore.setState({ value: 100 });

    return;
  }

  useProgressStore.setState({ message: undefined, value: 0 });

  if (abortSignal.aborted) return;

  Share.share({
    dialogTitle,
    title: title || fileName,
    url: savedFile.uri,
  }).catch(e => {
    e.errorMessage !== "Share canceled" && onError?.();
    console.warn("[Share] - ", e);
  });
}
