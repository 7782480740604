import { SIMULATE_APP_UPDATE_AVAILABLE } from "components/App/AppUpdate";
import { environmentFlags } from "components/FeatureFlags";
import { FeatureFlagModal } from "components/FeatureFlags/FeatureFlagModal";
import { ModalProps } from "components/ModalKit/Modal";
import { Footer, Header, Main } from "components/ModalKit/Parts";
import { StandardModal } from "components/Modals";
import { Button } from "components/design-system/Button";
import Hr from "components/design-system/Hr";
import {
  defaultRoutesStack,
  superTabsDefaults,
} from "components/routing/utils";
import { useHistory } from "react-router";
import useModalStore from "store/useModalStore";
import useRoutesStore from "store/useRoutesStore";

type FieldSetProps = {
  children: React.ReactNode;
  label: string;
};

const FieldSet = ({ children, label }: FieldSetProps) => {
  return (
    <fieldset>
      <div className="flex flex-row items-center mt-16">
        <span className="ml-8 text-base font-semibold">{label}</span>
      </div>
      {children}
    </fieldset>
  );
};

const Field = ({
  children,
  label,
}: {
  children: React.ReactNode;
  label?: string;
}) => (
  <div className="space-between flex relative flex-col mt-18 w-full md:flex-row">
    {label && (
      <div className="md:basis-[200px] text-base text-text-strong">{label}</div>
    )}
    {children}
  </div>
);

const FeatureFlags = () => {
  const { openModal } = useModalStore(({ openModal }) => ({ openModal }));

  return (
    environmentFlags.length && (
      <FieldSet label="Feature flags">
        <Field>
          <div className="px-10">
            <Button
              onClick={() =>
                openModal(
                  <FeatureFlagModal environmentFlags={environmentFlags} />
                )
              }
              type="button"
            >
              Open feature flags modal
            </Button>
          </div>
        </Field>
      </FieldSet>
    )
  );
};

const RouteMemoryReport = () => {
  const history = useHistory();

  return (
    <FieldSet label="Routes in Memory (Desktop)">
      <Field>
        <div className="px-10">
          <Button
            onClick={() => {
              useRoutesStore.setState({
                restoreRoute: "/inbox",
                routes: superTabsDefaults,
                routesStack: defaultRoutesStack,
              });
              history.push("/inbox");
            }}
            type="button"
          >
            Debugging: clear route memory
          </Button>
          <div className="text-footnote">
            {Object.entries(useRoutesStore.getState().routes).map(entry => {
              const [key, value] = entry;
              return (
                <p key={key}>
                  <strong>{`${key}:`}</strong>
                  <br />
                  {`${value}`}
                </p>
              );
            })}
          </div>
        </div>
      </Field>
    </FieldSet>
  );
};

const DeveloperModal = (props: ModalProps) => {
  return (
    <StandardModal
      header={
        <Header variant="bordered">
          <h3 className="m-0">Developer!</h3>
        </Header>
      }
      {...props}
    >
      <Main className="px-16 md:px-32 pb-20">
        <FeatureFlags />
        <Hr className="w-full mt-20 mb-10" />
        <RouteMemoryReport />
        <Hr className="w-full mt-20 mb-10" />
        <FieldSet label="App update available">
          <Field>
            <div className="px-10">
              <Button
                onClick={() => {
                  localStorage.setItem(SIMULATE_APP_UPDATE_AVAILABLE, "true");
                  window.location.reload();
                }}
                type="button"
              >
                Simulate update available
              </Button>
            </div>
          </Field>
        </FieldSet>
      </Main>
      <Footer flexboxClassName="justify-between items-center" />
    </StandardModal>
  );
};

export default DeveloperModal;
