import { Button } from "components/design-system/Button";
import { HTMLProps } from "react";
import tw from "utils/tw";

type Props = HTMLProps<HTMLDivElement> & {
  children: JSX.Element;
  className?: string;
  onRemove: () => void;
};

const DisposableElement = ({
  children,
  className,
  onRemove,
}: WithChildren<Props>): JSX.Element => (
  <div className={tw("group/disposable-element pt-10 pr-10", className)}>
    <div className="relative rounded-lg z-0">
      {children}

      <Button
        buttonStyle="none"
        buttonType="none"
        className={tw(
          "disposable-element-close",
          "absolute -top-10 -right-10 pb-10 pl-10 z-1",
          "md:hidden group-hover/disposable-element:md:flex"
        )}
        data-testid="disposable-close-btn"
        onClick={onRemove}
      >
        <svg // fill and stroke colors are set in CSS, find ".disposable-element-close"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="none"
          viewBox="0 0 20 20"
        >
          <rect
            width="18"
            height="18"
            x="1"
            y="1"
            fill="#44494D"
            stroke="#fff"
            strokeWidth="2"
            rx="9"
          />
          <path
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth="1.5"
            d="m7 7 6 6m0-6-6 6"
          />
        </svg>
      </Button>
    </div>
  </div>
);

export default DisposableElement;
