import { ThreadEdgeSimple } from "@utility-types";
import { flipSpring } from "components/Animated/utils";
import { Skeleton } from "components/Skeleton";
import ViewScrollContainer from "components/design-system/ui/ViewScrollContainer";
import {
  SectionItem,
  animate,
} from "components/design-system/ui/sections-sidebar";
import { usePartitionState } from "components/routing/RoutingPartition";
import {
  routeParams as getRouteParams,
  locationFromRoute,
  routeToThread,
} from "components/routing/utils";
import useListAutoSelect from "components/thread/ThreadList/hooks/useListAutoSelect";
import { useThreadListData } from "components/threads-list/hooks";
import { ThreadsMailbox, ThreadsOrder } from "generated/graphql";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Flipped, Flipper } from "react-flip-toolkit";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useHistory } from "react-router";
import useAppStateStore from "store/useAppStateStore";
import env from "utils/processEnv";
import tw from "utils/tw";
import AIListItem from "./AIListItem";
import { AI_SIDEBAR_ID } from "./AIMain";
import useGroupByDate from "./hooks/useGroupByDate";

const AIThreadList = ({
  scrollRef,
  skipAutoSelect = false,
}: {
  scrollRef?: (el: HTMLDivElement | null) => void;
  skipAutoSelect?: boolean;
}) => {
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));

  const history = useHistory();
  const { route } = usePartitionState(({ route }) => ({ route }));
  const routeParams = getRouteParams(locationFromRoute(route));
  const { threadID: selectedID, view } = routeParams;

  const threadListData = useThreadListData({
    mailbox: ThreadsMailbox.Ai,
    order: ThreadsOrder.Created,
    pageSize: 29,
    recipientID: env.glueAIBotID,
  });

  const {
    hasNextPage,
    loadNextPage,
    result: { error, loading, threadEdges },
  } = threadListData;

  /**
   * We could just do this:
   *
   * const edges = useMemo(
   *   () => threadEdges?.slice().reverse() ?? [],
   *   [threadEdges]
   * );
   *
   * ... but we can see the thread list refetching and re-rendering when foregrounding the app;
   * so we use a ref to store the thread list, hiding the refetch when app is brought to the foreground,
   * and we use state and effects to manually manage updates to the thread list when loading more, or posting a new thread.
   */
  const [shouldUpdate, setShouldUpdate] = useState({});

  const threadEdgesRef = useRef<ThreadEdgeSimple[]>([]);
  if (!threadEdgesRef.current.length) {
    threadEdgesRef.current = threadEdges?.slice() ?? [];
  }

  const hasLoaded = !!threadEdges;
  const [edges, setEdges] = useState<ThreadEdgeSimple[]>([]);

  useLayoutEffect(() => {
    setEdges(threadEdgesRef.current.slice().reverse());
  }, [hasLoaded, shouldUpdate]);

  useEffect(() => {
    if (!threadEdges || !threadEdgesRef.current.length) return;

    const newEdges = threadEdges.filter(
      edge =>
        !threadEdgesRef.current.some(
          existingEdge => existingEdge.node.id === edge.node.id
        )
    );

    if (!newEdges.length) return;

    const nextEdge = newEdges[0];
    const prevEdge = threadEdgesRef.current[threadEdgesRef.current.length - 1];

    if (nextEdge && prevEdge) {
      const isNewer = nextEdge.node.createdAt > prevEdge.node.createdAt;
      if (isNewer) {
        threadEdgesRef.current = [...threadEdgesRef.current, ...newEdges];
        setShouldUpdate({});
        return;
      }
      threadEdgesRef.current = [...newEdges, ...threadEdgesRef.current];
      setShouldUpdate({});
    }
  }, [threadEdges]);

  const [scrollSentryRef, { rootRef: scrollListRef }] = useInfiniteScroll({
    disabled: !!error,
    hasNextPage,
    loading,
    onLoadMore: () => {
      loadNextPage().then(() => {
        setShouldUpdate({});
      });
    },
    rootMargin: "0px 0px 200px 0px",
  });

  const dateRanges = useGroupByDate(
    edges.map(edge => ({
      createdAt: edge.node.createdAt,
      item: edge,
    }))
  );

  const dateRangesFlat: (string | ThreadEdgeSimple)[] = useMemo(() => {
    return Array.from(dateRanges).reduce<(string | ThreadEdgeSimple)[]>(
      (acc, [dateRange, threads]) => {
        acc.push(dateRange);
        threads.forEach(thread => {
          acc.push(thread);
        });
        return acc;
      },
      []
    );
  }, [dateRanges]);

  const navigateToThreadID = (
    e:
      | React.KeyboardEvent<HTMLDivElement>
      | React.MouseEvent<HTMLDivElement>
      | undefined,
    threadID?: string
  ) => {
    if (!threadID) return;
    const to = e?.ctrlKey || e?.metaKey ? "secondary" : "primary";
    history.push(routeToThread({ superTab: "ai", threadID, to }));
  };

  useListAutoSelect({
    edges,
    selectedID: selectedID,
    selectID: id => navigateToThreadID(undefined, id),
    skip: skipAutoSelect || !!view,
  });

  return (
    <ViewScrollContainer
      accessories={undefined}
      className="pt-8 px-8"
      id={breakpointMD ? AI_SIDEBAR_ID : undefined}
      scrollRef={scrollRef}
    >
      <div ref={scrollListRef}>
        <Flipper
          key={threadEdges ? "ready" : "loading"}
          element="ol"
          className="overflow-x-hidden"
          flipKey={dateRangesFlat
            .map(item => (typeof item === "string" ? item : item.id))
            .join()}
        >
          {dateRangesFlat.map(item =>
            typeof item === "string" ? (
              <Flipped
                key={item}
                flipId={item}
                onAppear={(el, i) => animate(el, i)}
                onExit={(_el, _i, remove) => remove()}
                spring={flipSpring}
              >
                <div
                  key={item}
                  className={tw(
                    "flex items-center h-32 pr-4 pl-8 text-footnote-bold text-text-secondary",
                    {
                      "mt-16": item !== dateRangesFlat[0],
                    }
                  )}
                >
                  {item}
                </div>
              </Flipped>
            ) : (
              <SectionItem
                key={item.node.id}
                flipId={item.node.id}
                onClick={e => navigateToThreadID(e, item.node.id)}
                itemData={item}
                dismissOnRightSwipe={true}
              >
                <AIListItem
                  isSelected={selectedID === item.node.id}
                  subject={item.node.subject}
                />
              </SectionItem>
            )
          )}
        </Flipper>

        {hasNextPage && (
          <div ref={scrollSentryRef} data-testid="ai-sidebar-load-more">
            {loading && (
              <Skeleton
                className="border border-border-container rounded-lg"
                height="32px"
                width="100%"
              />
            )}
          </div>
        )}
      </div>
    </ViewScrollContainer>
  );
};

export default AIThreadList;
