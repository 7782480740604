import { Reference, useApolloClient } from "@apollo/client";
import { useCallback, useEffect, useReducer } from "react";

import { markdownToPlainText } from "md";
import { useAbortController } from "use-abort-controller-hook";
import { useDebouncedCallback } from "use-debounce";

import { Draft, Recipient, Thread } from "@utility-types";
import {
  Addable,
  DestinationMailbox,
  DraftListDocument,
  DraftMessage,
  useDeleteDraftMutation,
  useSaveDraftMutation,
  useSendDraftMutation,
} from "generated/graphql";
import useCacheEvict from "hooks/state/useCacheEvict";
import useThreadCacheUpdate from "hooks/thread/useThreadCacheUpdate";
import useComponentMounted from "hooks/useComponentMounted";
import saveDraftsOption from "utils/drafts/saveDraftsOptions";
import isDefined from "utils/isDefined";
import { removeLonelyLinks } from "utils/message/removeLonelyLinks";

import useToastStore, {
  ToastType,
} from "components/design-system/ui/ToastKit/useToastStore";
import { useValidateMessage } from "components/thread/hooks";
import { useDraftsUtils } from "components/views/inbox/InboxMain/hooks";
import useDraftMessagesStore from "store/useDraftMessagesStore";

import useAppUnfurlSetupMessage from "components/threads/hooks/useAppUnfurlSetupMessage";
import {
  DraftReducer,
  InitialState,
  draftToForm,
  formToInput,
} from "../DraftReducer";
import { DraftForm } from "../types";

type Props = {
  draft?: Draft;
  initialDraft?: Partial<DraftForm>;
  onFinish?: (result?: Thread | "deleted") => void;
  onSave?: (draftID: string) => void;
};

export function useDraftActions({
  draft,
  initialDraft,
  onFinish,
  onSave,
}: Props) {
  const apolloClient = useApolloClient();
  const isMounted = useComponentMounted();
  const saveAbortController = useAbortController();
  const { validateDraft } = useValidateMessage();
  const { openToast } = useToastStore(({ openToast }) => ({ openToast }));
  const { removeDraft } = useDraftMessagesStore(({ removeDraft }) => ({
    removeDraft,
  }));

  // Draft State

  const [compose, dispatch] = useReducer(DraftReducer, {
    ...InitialState,
    draftID: draft?.id,
    draftForm: {
      ...InitialState.draftForm,
      ...initialDraft,
      ...(draft ? draftToForm(draft) : {}),
    },
    pending: draft?.id ? "load" : false,
  });

  const updateDraftCache = useCallback(() => {
    if (!compose.draftID) return;

    const draft = compose.draftForm;
    const success = apolloClient.cache.modify({
      id: `Draft:${compose.draftID}`,
      fields: {
        subject: (): string => draft.subject ?? "",
        recipients: (): Recipient[] => draft.recipients,
        recipientsAddable: (): Addable =>
          draft.recipientsAddable ?? Addable.Anyone,
        message: (
          _,
          { toReference }
        ): Omit<DraftMessage, "attachments"> & {
          attachments: Reference[];
        } => ({
          __typename: "DraftMessage",
          text: draft.message.text,
          textPreview: markdownToPlainText(draft.message.text).slice(0, 150),
          attachments: draft.message.attachments
            .map(a => toReference(a))
            .filter(isDefined),
        }),
      },
    });

    if (success) {
      onSave?.(compose.draftID);
    } else {
      console.warn("Draft not saved to cache.");
    }
  }, [apolloClient.cache, compose.draftForm, compose.draftID, onSave]);

  // Save Draft

  const [saveDraftMutation] = useSaveDraftMutation({
    context: { fetchOptions: saveAbortController },
    ...saveDraftsOption(),
  });

  const onDraftSave = useCallback(
    (draft: Draft, userAction: boolean) => {
      onSave?.(draft.id);

      if (userAction) {
        onFinish?.();

        openToast({
          content: "Saved draft successfully.",
          icon: "Save",
          type: ToastType.DRAFT,
        });
      }
    },
    [onFinish, openToast, onSave]
  );

  const saveDraft = useCallback(
    (userAction = false) =>
      new Promise<Draft | void>(resolveSave => {
        // Don't attempt to save if deleting or sending, or if
        // saving for the first time (avoid race condition).
        if (
          compose.pending &&
          (compose.pending !== "save" || !compose.draftID)
        ) {
          return;
        }

        const isReply = !!compose.draftForm.replyToMessage;
        const hasSubject = !!compose.draftForm.subject;
        const { text, attachments } = compose.draftForm.message;
        const hasContent = !!text || attachments.length > 0;

        // Auto-save: only if there is a subject or a reply message
        if (!userAction && (!hasSubject || (isReply && !hasContent))) {
          return;
        }

        const input = formToInput(compose.draftForm);

        if (!compose.dirty || !validateDraft(input, "save")) {
          return;
        }

        saveAbortController.abort(); // cancel in-flight save

        dispatch({ type: "save" });

        saveDraftMutation({
          variables: { id: compose.draftID, input },
          refetchQueries: compose.draftID ? [] : [DraftListDocument],
          awaitRefetchQueries: true,
        })
          .catch(err => {
            console.warn("Error: [saveDraft] - ", err);
            dispatch({ type: "error" });
            return { data: undefined };
          })
          .then(({ data }) => {
            if (!data || !isMounted.current) return;
            const { saveDraft: draft } = data;
            dispatch({ type: "saved", draft });
            onDraftSave(draft, userAction);
            resolveSave(draft);
          });
      }),
    [
      compose.dirty,
      compose.draftForm,
      compose.draftID,
      compose.pending,
      isMounted,
      onDraftSave,
      saveAbortController,
      saveDraftMutation,
      validateDraft,
    ]
  );

  const debouncedSaveDraft = useDebouncedCallback(
    useCallback(saveDraft, [saveDraft]),
    500
  );

  const abortPendingSave = useCallback(() => {
    saveAbortController.abort();
    debouncedSaveDraft.cancel();
  }, [debouncedSaveDraft, saveAbortController]);

  // Send Draft

  const { evictNode } = useCacheEvict();
  const { onThreadNew } = useThreadCacheUpdate();

  const [sendDraftMutation] = useSendDraftMutation();
  const { sendSetupMessages } = useAppUnfurlSetupMessage();

  const onDraftSend = useCallback(
    (thread: Thread) => {
      onFinish?.(thread);
    },
    [onFinish]
  );

  const sendDraft = useCallback(
    () =>
      new Promise<Thread | void>(resolveSend => {
        if (compose.pending && compose.pending !== "save") return;

        const input = formToInput(compose.draftForm);

        input.message.text = removeLonelyLinks(
          input.message.text.trim(),
          compose.draftForm.message.attachments
        );

        if (!validateDraft(input, "send")) {
          return;
        }

        abortPendingSave();

        dispatch({ type: "send" });

        const id = compose.draftID;

        sendDraftMutation({
          variables: {
            id,
            input: {
              ...input,
              mailboxes: [DestinationMailbox.Inbox],
            },
          },
          refetchQueries: [DraftListDocument],
          update: (cache, { data }) => {
            if (!data) return;
            if (id) {
              evictNode({ id }, cache);
            }
            onThreadNew(data.sendDraft, cache);
          },
        })
          .catch(err => {
            console.warn("Error: [sendDraft] - ", err);
            dispatch({ type: "error" });
            return { data: undefined };
          })
          .then(({ data }) => {
            if (!data || !isMounted.current) return;
            onDraftSend(data.sendDraft);
            resolveSend(data.sendDraft);

            const threadID = data.sendDraft.id;
            const messageID = data.sendDraft.firstMessage?.id;
            if (messageID) {
              sendSetupMessages(
                messageID,
                threadID,
                compose.draftForm.appUnfurlSetups
              );
            }
          });
      }),
    [
      abortPendingSave,
      compose.draftForm,
      compose.draftID,
      compose.pending,
      evictNode,
      isMounted,
      onDraftSend,
      onThreadNew,
      sendDraftMutation,
      sendSetupMessages,
      validateDraft,
    ]
  );

  // Delete Draft

  const { handleUndo } = useDraftsUtils();

  const onDraftDelete = useCallback(
    (draft: { id: string } | undefined) => {
      if (draft) {
        removeDraft({ draftID: draft.id });
        openToast({
          content: "Draft deleted",
          dismiss: 5000,
          icon: "Trash",
          type: ToastType.DRAFT,
          undo: () => handleUndo(draft.id),
        });
      }

      onFinish?.("deleted");
    },
    [onFinish, removeDraft, openToast, handleUndo]
  );

  const [deleteDraftMutation] = useDeleteDraftMutation();

  const deleteDraft = useCallback(
    (draftID?: string) =>
      new Promise<boolean>(resolveDelete => {
        if (compose.pending && compose.pending !== "save") return false;

        const id = draftID || compose.draftID;
        if (!id) return onDraftDelete(undefined);

        abortPendingSave();

        deleteDraftMutation({
          variables: { id },
          refetchQueries: [DraftListDocument],
          update: c => evictNode({ id }, c),
        })
          .catch(err => {
            console.warn("Error: [deleteDraft] - ", err);
            dispatch({ type: "error" });
            resolveDelete(false);
          })
          .then(() => {
            if (!isMounted.current) return;
            onDraftDelete({ id });
            resolveDelete(true);
          });

        dispatch({ type: "delete" });
      }),
    [
      abortPendingSave,
      compose.draftID,
      compose.pending,
      deleteDraftMutation,
      evictNode,
      isMounted,
      onDraftDelete,
    ]
  );

  // save draft automatically when dirty

  useEffect(() => {
    if (!compose.dirty) return;

    debouncedSaveDraft();
    updateDraftCache();
  }, [compose.dirty, compose.draftForm, debouncedSaveDraft, updateDraftCache]);

  return {
    compose,
    dispatch,
    deleteDraft,
    saveDraft: debouncedSaveDraft,
    sendDraft,
  };
}

export default useDraftActions;
