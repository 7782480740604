import { Button } from "components/design-system/Button";
import useLocalSettingsStore from "store/useLocalSettingsStore";
import tw from "utils/tw";

type Props = {
  buttonElement?: JSX.Element;
  className?: string;
  closable?: boolean;
  collapseButtonClassName?: string;
  collapsed?: boolean;
  grow?: boolean;
  onClickCollapse?: () => void;
  onClickTitle?: () => void;
  sectionKey?: string;
  title: string | JSX.Element;
  titleClassName?: string;
  unreadCount?: number;
  unreadMentions?: number;
};

/**
 * @param children Element that will live next to the title
 * @param className Adds additional classes to the main container
 * @param closable Replaces the arrow icon with the close icon
 * @param collapseButtonClassName Adds additional classes to the collapse button
 * @param grow Determines if children will be aligned to the right
 * @param onClickCollapse Action that will be executed after clicking the collapse button
 * @param onClickTitle Action that will be called when the title is clicked
 * @param sectionKey Used to track the collapsed state of the section
 * @param title Text or element that will be displayed as the title of the section
 * @param titleClassName Adds additional classes to the title container
 * @param unreadCount Display the count next to the name
 */
const SectionHeader = ({
  buttonElement,
  children,
  className,
  closable = false,
  collapseButtonClassName,
  collapsed = false,
  grow = true,
  onClickCollapse,
  onClickTitle: onClickTitleProps,
  sectionKey,
  title,
  titleClassName,
  unreadCount = 0,
  unreadMentions = 0,
}: WithChildren<Props>) => {
  const { updateSidebarSections } = useLocalSettingsStore(
    ({ updateSidebarSections }) => ({
      updateSidebarSections,
    })
  );

  const handleCollapse = () => {
    onClickCollapse?.();
    sectionKey && updateSidebarSections(sectionKey, !collapsed);
  };

  const onClickTitle = (e: React.MouseEvent) => {
    if (!onClickTitleProps) return;
    e.preventDefault();
    e.stopPropagation();
    onClickTitleProps();
  };

  return (
    <div
      className={tw(
        "group relative flex items-center h-32 pl-12 pr-4 select-none",
        "focus-visible-shadow rounded",
        className
      )}
      data-testid="sectionHeader"
    >
      <div
        className={tw(
          "flex items-center min-w-0 text-sm font-semibold text-text-secondary",
          titleClassName
        )}
        data-testid="section-header-title"
        onClick={onClickTitle}
      >
        {title}
      </div>
      {grow && <div className="grow" />}
      {children}
      {!grow && <div className="grow" />}
      {unreadCount > 0 && collapsed ? (
        <span
          className={tw(
            "h-20 min-w-20 mr-8 p-4 rounded-md flex justify-center items-center text-sm cursor-pointer",
            "transition-colors text-caption-bold text-text-action-inverse bg-background-alert-subtle hover:bg-background-alert-subtle-hover",
            {
              "!bg-background-alert-strong hover:!bg-background-alert-strong-hover":
                unreadMentions > 0,
            }
          )}
          onClick={closable ? undefined : handleCollapse}
        >
          {unreadCount < 99 ? unreadCount : "99+"}
        </span>
      ) : (
        (buttonElement ?? (
          <Button
            buttonStyle="icon-secondary"
            className={tw(
              "h-36 w-36 !px-0 justify-center",
              collapseButtonClassName
            )}
            data-testid="collapseButton"
            icon={closable ? "Close" : "ChevronRight"}
            iconClassName={`ease-spring duration-350 transform ${
              !collapsed ? "rotate-90" : ""
            }`}
            iconSize={20}
            onClick={closable ? undefined : handleCollapse}
            onKeyDown={e => {
              if (
                !(e.target instanceof Element) ||
                e.target.tagName === "BUTTON"
              )
                return;
              e.key === "Enter" && handleCollapse();
            }}
            tabIndex={-1}
          />
        ))
      )}
    </div>
  );
};

export default SectionHeader;
