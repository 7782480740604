import { glueCreateZustand } from "./helper/glueCreate";

const STORE_NAME = "PhotoSwipeStore";

type PhotoSwipeStore = {
  currentImageOriginalURL?: string;
  open: boolean;
  photoSwipeLayoutElement: HTMLDialogElement | null;
};

const initialState: PhotoSwipeStore = {
  open: false,
  photoSwipeLayoutElement: null,
};

const usePhotoSwipeStore = glueCreateZustand<PhotoSwipeStore>({
  name: STORE_NAME,
})(() => initialState);

export default usePhotoSwipeStore;
