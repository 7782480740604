import { Button } from "components/design-system/Button";
import useModalStore from "store/useModalStore";
import tw from "utils/tw";

import useToastStore from "components/design-system/ui/ToastKit/useToastStore";
import useGroupRecipients from "hooks/useGroupRecipients";

import { ComponentProps } from "react";

import { ModalProps } from "components/ModalKit/Modal";
import { Main } from "components/ModalKit/Parts";

import { StandardModal } from "components/Modals";

import useAppStateStore from "store/useAppStateStore";
import AICompose from "./AIViews/AICompose";

type Props = ModalProps & Omit<ComponentProps<typeof AICompose>, "isModal">;

const AIComposeModal = ({ ...props }: Props) => (
  <StandardModal
    closeButtonPosition="right"
    closeButtonTitle="Save & close"
    contentClassName="md:!max-w-[800px]"
    {...props}
  >
    <Main>
      <AICompose isModal />
    </Main>
  </StandardModal>
);

const NewThreadButton = () => {
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));
  const groupRecipients = useGroupRecipients();

  const { toastConfig } = useToastStore(({ toastConfig }) => ({ toastConfig }));

  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));

  const handleOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    openModal(
      <AIComposeModal initialDraft={{ recipients: groupRecipients }} />
    );
  };

  if (!breakpointMD && toastConfig.show) return null;

  return (
    <Button
      buttonFont="normal"
      buttonStyle="none"
      className={tw(
        "bg-background-action justify-center px-16 py-8 rounded-[38px] shrink-0 text-text-action-inverse z-1",
        "h-56 w-56 md:h-44 md:w-44",
        "absolute right-16 bottom-12",
        "md:relative md:right-auto md:bottom-auto"
      )}
      icon="Compose"
      iconSize={20}
      iconStroke={1.5}
      onClick={handleOnClick}
      tooltip="New thread"
      type="button"
    />
  );
};

export default NewThreadButton;
