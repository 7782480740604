import AppTabLayout from "components/App/AppLayoutMobile/AppTabLayout";
import MobileProfilePane from "components/design-system/ui/ProfilePane/MobileProfilePane";
import SidebarAccessoryContainer from "components/design-system/ui/SidebarAccessoryContainer";
import { usePartitionState } from "components/routing/RoutingPartition";
import {
  TabName,
  routeParams as getRouteParams,
  locationFromRoute,
} from "components/routing/utils";
import AIThreadPane from "./AIMain/AIThreadPane";
import AIThreadList from "./AIThreadList";
import AICompose from "./AIViews/AICompose";
import NewAIThreadButton from "./NewAIThreadButton";

const AIMobileMain = ({
  scrollRef,
}: {
  scrollRef: (el: HTMLDivElement | null) => void;
}) => {
  return <AIThreadList scrollRef={scrollRef} skipAutoSelect />;
};

const AIMobile = ({
  scrollRef,
}: {
  scrollRef: (el: HTMLDivElement | null) => void;
}) => {
  const { route } = usePartitionState(({ route }) => ({ route }));
  const { pathname, search } = locationFromRoute(route);
  const { d, messageID, threadID, view } = getRouteParams({
    pathname,
    search,
  });

  const detailContent: React.ReactNode = (() => {
    if (d) {
      return <MobileProfilePane id={d} />;
    }

    let content: React.ReactNode = threadID ? (
      <AIThreadPane messageID={messageID} threadID={threadID} />
    ) : null;

    if (view === "compose") {
      content = <AICompose />;
    }

    return content;
  })();

  return (
    <AppTabLayout
      buttons={null}
      detailChildren={detailContent}
      name={TabName.AI}
    >
      <div className="flex flex-col grow h-full min-h-0 relative w-full">
        <AIMobileMain scrollRef={scrollRef} />
      </div>

      <SidebarAccessoryContainer>
        <NewAIThreadButton />
      </SidebarAccessoryContainer>
    </AppTabLayout>
  );
};

export default AIMobile;
