import { ComponentProps, useCallback } from "react";

import { ApolloCache, gql, useApolloClient } from "@apollo/client";

import { Mailbox, ThreadEdgeSimple } from "@utility-types";
import { readThreadList } from "apollo/cache/threadHelpers";
import useToastStore, {
  ToastType,
} from "components/design-system/ui/ToastKit/useToastStore";
import {
  MailboxCountsDocument,
  PersistentChatsDocument,
  ThreadEdgeFieldsFragmentDoc,
  ThreadSubscription,
  useMarkAllThreadEdgesMutation,
  useMarkThreadEdgesMutation,
} from "generated/graphql";
import { useNativeHaptics } from "hooks/native/useNativeHaptics";
import filterActiveQueries from "utils/filterActiveQueries";

import ActionSnackbar from "components/SnackBar/ActionSnackbar";

import useThreadCacheUpdate from "./useThreadCacheUpdate";

type ActionOptions = { showSnackbar?: boolean; skipUpdate?: boolean };

type ThreadFilter = {
  excludeChats?: boolean;
  excludeStarred?: boolean;
  mailbox?: Mailbox;
  recipientID?: string;
};

export type ThreadSelectSome = {
  filter?: ThreadFilter;
  selectMode?: "some";
  threadEdges: ThreadEdgeSimple[];
};

export type ThreadSelectAll = {
  excludedEdgeIDs: Readonly<Set<string>>;
  filter?: ThreadFilter;
  persistentChatEdge?: ThreadEdgeSimple | null;
  selectMode: "all";
};

export type ThreadActions = "archive" | "star" | "delete";
export type ThreadSelection = ThreadSelectSome | ThreadSelectAll;

type BulkEditActions = {
  archived?: boolean;
  remindAt?: string | null;
  read?: boolean;
  seen?: boolean;
  starred?: boolean;
  subscription?: ThreadSubscription;
};

type InboxThreadActionsResult = {
  markThreadEdges: (
    params: BulkEditActions & {
      selection: ThreadSelection;
    } & ActionOptions
  ) => void;
  toggleThreadArchived: (
    threadEdge: ThreadEdgeSimple,
    archived?: boolean,
    options?: ActionOptions
  ) => void;
  toggleThreadRead: (
    threadEdge: ThreadEdgeSimple,
    read?: boolean,
    options?: ActionOptions
  ) => void;
  toggleThreadSeen: (
    threadEdge: ThreadEdgeSimple,
    seen?: boolean,
    options?: { showSnackbar: boolean }
  ) => void;
  toggleThreadStarred: (
    threadEdge: ThreadEdgeSimple,
    starred?: boolean,
    options?: ActionOptions
  ) => void;
  toggleThreadSubscribed: (threadEdge: ThreadEdgeSimple) => void;
};

const useInboxThreadActions: () => InboxThreadActionsResult = () => {
  const apolloClient = useApolloClient();

  const { lightImpactHaptic } = useNativeHaptics();

  const [markThreadEdgesMutation] = useMarkThreadEdgesMutation({
    errorPolicy: "all",
  });

  const [markAllThreadEdgesMutation] = useMarkAllThreadEdgesMutation({
    errorPolicy: "all",
  });

  const { onThreadActions } = useThreadCacheUpdate();

  const nodeEvicted = useCallback(
    (threadEdge: ThreadEdgeSimple) =>
      !apolloClient.readFragment({
        fragment: gql`
          fragment ID on ThreadEdge {
            id
          }
        `,
        id: apolloClient.cache.identify(threadEdge),
      }),
    [apolloClient]
  );

  const sidebarCountsQueries = useCallback(
    () =>
      filterActiveQueries(apolloClient, [
        MailboxCountsDocument,
        PersistentChatsDocument,
      ]),
    [apolloClient]
  );

  const threadEdgesToMark = useCallback(
    ({
      actions: { archived, remindAt, read, seen, starred, subscription },
      cache,
      selection,
      skipUpdate,
    }: {
      actions: BulkEditActions;
      cache: ApolloCache<unknown>;
      selection: ThreadSelection;
      skipUpdate?: boolean;
    }) => {
      let threadEdges: ThreadEdgeSimple[];

      if (selection.selectMode === "all") {
        const { excludedEdgeIDs, filter, persistentChatEdge } = selection;
        threadEdges = [
          ...(persistentChatEdge ? [persistentChatEdge] : []),
          ...(readThreadList(filter, cache)?.threads.edges || []),
        ].filter(e => !excludedEdgeIDs?.has(e.id));
      } else {
        ({ threadEdges } = selection);
      }

      const edges = threadEdges?.filter(edge => !nodeEvicted(edge));
      return skipUpdate
        ? edges
        : edges.filter(
            edge =>
              (archived !== undefined && edge.isArchived !== archived) ||
              (remindAt !== undefined && edge.remindAt !== remindAt) ||
              (read !== undefined && edge.isRead !== read) ||
              (seen !== undefined && edge.isSeen !== seen) ||
              (starred !== undefined && edge.isStarred !== starred) ||
              (subscription !== undefined && edge.subscription !== subscription)
          );
    },
    [nodeEvicted]
  );

  const threadEdgeWithActions = useCallback(
    ({
      actions: { archived, remindAt, read, seen, starred, subscription },
      threadEdge,
    }: {
      actions: BulkEditActions;
      threadEdge: ThreadEdgeSimple;
    }): ThreadEdgeSimple => {
      const updatedEdge = { ...threadEdge };
      if (archived !== undefined) updatedEdge.isArchived = archived;
      if (remindAt !== undefined) updatedEdge.remindAt = remindAt;
      if (remindAt) updatedEdge.isArchived = true;
      if (archived === false) updatedEdge.remindAt = null;
      if (read !== undefined) updatedEdge.isRead = read;
      if (read) {
        updatedEdge.isSeen = true;
        updatedEdge.unreadMessageCounts = {
          ...updatedEdge.unreadMessageCounts,
          mentioned: 0,
          total: 0,
          unseen: 0,
        };
      }
      if (seen !== undefined) updatedEdge.isSeen = seen;
      if (starred !== undefined) updatedEdge.isStarred = starred;
      if (subscription !== undefined) updatedEdge.subscription = subscription;
      if (subscription && subscription !== ThreadSubscription.Inbox) {
        updatedEdge.isArchived = true;
      }
      return updatedEdge;
    },
    []
  );

  const markThreadEdgeInputs = ({
    archived,
    remindAt,
    read,
    seen,
    starred,
    subscription,
  }: BulkEditActions) => ({
    archived:
      archived !== undefined ? { isArchived: archived, remindAt } : undefined,
    read: read !== undefined ? { isRead: read } : undefined,
    seen: seen !== undefined ? { isSeen: seen } : undefined,
    starred: starred !== undefined ? { isStarred: starred } : undefined,
    subscribed: subscription !== undefined ? { subscription } : undefined,
  });

  const markThreadEdgesMutationOptions = useCallback(
    ({
      actions,
      edgesToMark,
      selection,
    }: {
      actions: BulkEditActions;
      edgesToMark: ThreadEdgeSimple[];
      selection: ThreadSelectSome;
    }): Parameters<typeof markThreadEdgesMutation>[0] => ({
      optimisticResponse: {
        markThreadEdges: [
          ...edgesToMark.map(threadEdge =>
            threadEdgeWithActions({ actions, threadEdge })
          ),
        ],
      },
      update: cache => {
        edgesToMark.forEach(threadEdge => {
          const updatedEdge = threadEdgeWithActions({ actions, threadEdge });
          onThreadActions(updatedEdge, threadEdge, actions, cache);
        });
      },
      variables: {
        ...markThreadEdgeInputs(actions),
        ids: selection.threadEdges.map(e => e.id),
      },
    }),
    [onThreadActions, threadEdgeWithActions]
  );

  const markAllThreadEdgesMutationOptions = useCallback(
    ({
      actions,
      edgesToMark,
      selection,
    }: {
      actions: BulkEditActions;
      edgesToMark: ThreadEdgeSimple[];
      selection: ThreadSelectAll;
    }): Parameters<typeof markAllThreadEdgesMutation>[0] => ({
      optimisticResponse: {
        markAllThreadEdges: edgesToMark.length,
      },
      refetchQueries: sidebarCountsQueries(),
      update: cache => {
        // TODO: optimistically update sidebar counts for mark-all-read case
        edgesToMark.forEach(threadEdge => {
          const updatedEdge = threadEdgeWithActions({ actions, threadEdge });
          cache.writeFragment({
            data: updatedEdge,
            fragment: ThreadEdgeFieldsFragmentDoc,
            fragmentName: "ThreadEdgeFields",
            id: cache.identify(threadEdge),
          });
          onThreadActions(updatedEdge, threadEdge, actions, cache);
        });
      },
      variables: {
        ...markThreadEdgeInputs(actions),
        filter: {
          ...selection.filter,
          excludeIDs: Array.from(selection.excludedEdgeIDs).map(
            (id: string) => id.split("-")[0] || id
          ),
        },
      },
    }),
    [onThreadActions, sidebarCountsQueries, threadEdgeWithActions]
  );

  const { openToast } = useToastStore(({ openToast }) => ({ openToast }));

  const openActionSnackbar = useCallback(
    ({
      actions: { archived, remindAt, read, starred, subscription },
      edgesToMark,
      selection,
    }: {
      actions: BulkEditActions;
      edgesToMark: ThreadEdgeSimple[];
      selection: ThreadSelection;
    }) => {
      const multiAction =
        selection.selectMode === "all" || selection.threadEdges.length > 1;
      const pluralize = (phrase: string): string =>
        phrase.replace("thread", multiAction ? "threads" : "thread");

      let snackbarOptions: ComponentProps<typeof ActionSnackbar> = {
        label: "",
      };

      if (archived !== undefined) {
        snackbarOptions = archived
          ? {
              Icon: "Check",
              iconProps: { strokeWidth: 3 },
              label: "Archived",
              undoTitle: "Move back to inbox",
              variant: ToastType.ARCHIVE,
            }
          : {
              Icon: "Inbox",
              label: "Unarchived",
              variant: ToastType.ARCHIVE,
            };
      }

      if (remindAt !== undefined) {
        snackbarOptions = remindAt
          ? {
              Icon: "ClockAlarm",
              label: "Reminder set",
              variant: ToastType.ARCHIVE,
            }
          : {
              Icon: "Trash",
              label: "Reminder removed",
              variant: ToastType.DRAFT,
            };
      }

      if (read !== undefined) {
        snackbarOptions = read
          ? {
              Icon: "Mail",
              label: "Marked read",
              undoTitle: "Mark unread",
              variant: ToastType.READ,
            }
          : {
              Icon: "Unread",
              iconContainerClassName: "snackbar-unread-mark",
              label: "Marked unread",
              variant: ToastType.READ,
            };
      }

      if (subscription !== undefined) {
        snackbarOptions =
          subscription === ThreadSubscription.Archive
            ? {
                Icon: "BellSmallFilled",
                label: pluralize("Unfollowed thread"),
                undoTitle: pluralize("Re-follow thread"),
                variant: ToastType.SUBSCRIPTION,
              }
            : {
                Icon: "Bell",
                label: pluralize("Followed thread"),
                variant: ToastType.SUBSCRIPTION,
              };
      }

      if (starred !== undefined) {
        snackbarOptions = !starred
          ? {
              Icon: "Star",
              label: "Unstarred",
              undoTitle: "Moved back to Starred",
              variant: ToastType.STARRED,
            }
          : {
              Icon: "Star",
              iconProps: {
                className: "text-interactive-primary fill-current",
              },
              label: "Moved to Starred",
              variant: ToastType.STARRED,
            };
      }

      const onClickUndo = () => {
        const undoOptions = {
          actions: {
            archived:
              archived !== undefined ? !archived : remindAt ? false : undefined,
            ...(remindAt !== undefined || archived
              ? { remindAt: remindAt ? null : edgesToMark[0]?.remindAt }
              : {}),
            read: read !== undefined ? !read : undefined,
            starred: starred !== undefined ? !starred : undefined,
            subscription:
              subscription !== undefined
                ? subscription === ThreadSubscription.Archive
                  ? ThreadSubscription.Inbox
                  : ThreadSubscription.Archive
                : undefined,
          },
          edgesToMark,
        };

        if (selection.selectMode === "all") {
          markAllThreadEdgesMutation(
            markAllThreadEdgesMutationOptions({ ...undoOptions, selection })
          );
        } else {
          markThreadEdgesMutation(
            markThreadEdgesMutationOptions({ ...undoOptions, selection })
          );
        }
        lightImpactHaptic();
      };

      if (snackbarOptions.label === "") return;

      openToast({
        content: snackbarOptions.label,
        dismiss: 5000,
        icon: snackbarOptions.Icon,
        type: snackbarOptions.variant,
        undo: onClickUndo,
      });
    },
    [
      lightImpactHaptic,
      markAllThreadEdgesMutation,
      markAllThreadEdgesMutationOptions,
      markThreadEdgesMutation,
      markThreadEdgesMutationOptions,
      openToast,
    ]
  );

  const markThreadEdges = useCallback(
    ({
      archived,
      remindAt,
      read,
      seen,
      selection,
      showSnackbar = true,
      skipUpdate,
      starred,
      subscription,
    }: BulkEditActions & {
      selection: ThreadSelection;
    } & ActionOptions) => {
      // keep track of original intended actions
      const userActions = { archived, remindAt, read, starred, subscription };

      // apply mark side-effects
      if (archived === false || read === false || starred) {
        archived = false;
        subscription = ThreadSubscription.Inbox;
      }
      if (subscription === ThreadSubscription.Inbox) {
        archived = false;
      }
      if (archived) {
        starred = false;
      }
      if (archived === false) {
        remindAt = null;
      }
      if (remindAt) {
        archived = true;
      }

      const actions = { archived, remindAt, read, seen, starred, subscription };
      const cache = apolloClient.cache;

      const edgesToMark = threadEdgesToMark({
        actions,
        cache,
        selection,
        skipUpdate,
      });

      if (!edgesToMark?.[0]) return;

      (selection.selectMode === "all"
        ? markAllThreadEdgesMutation(
            markAllThreadEdgesMutationOptions({
              actions,
              edgesToMark,
              selection,
            })
          )
        : markThreadEdgesMutation(
            markThreadEdgesMutationOptions({
              actions,
              edgesToMark,
              selection,
            })
          )
      ).then(({ data }) => {
        if (data && showSnackbar) {
          openActionSnackbar({ actions: userActions, edgesToMark, selection });
        }
      });
    },
    [
      apolloClient.cache,
      markAllThreadEdgesMutation,
      markAllThreadEdgesMutationOptions,
      markThreadEdgesMutation,
      markThreadEdgesMutationOptions,
      openActionSnackbar,
      threadEdgesToMark,
    ]
  );

  // Archived / un-archived

  const toggleThreadArchived = useCallback(
    async (
      threadEdge: ThreadEdgeSimple,
      flag?: boolean,
      options: ActionOptions = { showSnackbar: true }
    ) => {
      const archived = flag !== undefined ? flag : !threadEdge.isArchived;
      if (archived === threadEdge.isArchived) return;

      markThreadEdges({
        archived,
        selection: { threadEdges: [threadEdge] },
        ...options,
      });
    },
    [markThreadEdges]
  );

  // Read / unread

  const toggleThreadRead = useCallback(
    async (
      threadEdge: ThreadEdgeSimple,
      flag?: boolean,
      options: ActionOptions = { showSnackbar: true }
    ) => {
      const read = flag ?? !threadEdge.isRead;
      if (read === threadEdge.isRead) return;

      markThreadEdges({
        read,
        selection: { threadEdges: [threadEdge] },
        ...options,
      });
    },
    [markThreadEdges]
  );

  // Seen / unseen

  const toggleThreadSeen = useCallback(
    async (
      threadEdge: ThreadEdgeSimple,
      flag?: boolean,
      options: ActionOptions = { showSnackbar: false }
    ) => {
      const seen = flag ?? !threadEdge.isSeen;
      if (seen === threadEdge.isSeen) return;

      markThreadEdges({
        seen,
        selection: { threadEdges: [threadEdge] },
        ...options,
      });
    },
    [markThreadEdges]
  );

  // Follow / unfollow

  const toggleThreadSubscribed = useCallback(
    async (
      threadEdge: ThreadEdgeSimple,
      options: ActionOptions = { showSnackbar: true }
    ) => {
      const subscription =
        threadEdge.subscription === "inbox"
          ? ThreadSubscription.Archive
          : ThreadSubscription.Inbox;

      markThreadEdges({
        selection: { threadEdges: [threadEdge] },
        subscription,
        ...options,
      });
    },
    [markThreadEdges]
  );

  // Starred / un-starred

  const toggleThreadStarred = useCallback(
    async (
      threadEdge: ThreadEdgeSimple,
      flag?: boolean,
      options: ActionOptions = { showSnackbar: true }
    ) => {
      const starred = flag ?? !threadEdge.isStarred;
      if (starred === threadEdge.isStarred) return;

      markThreadEdges({
        selection: { threadEdges: [threadEdge] },
        starred: starred,
        ...options,
      });
    },
    [markThreadEdges]
  );

  return {
    markThreadEdges,
    toggleThreadArchived,
    toggleThreadRead,
    toggleThreadSeen,
    toggleThreadStarred,
    toggleThreadSubscribed,
  };
};

export default useInboxThreadActions;
