import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { sortBy } from "lodash-es";

import {
  GroupEdgeSimple,
  GroupSimple,
  WorkspaceEdgeSimple,
  nodeAs,
  nodeIs,
} from "@utility-types/graphql";
import useDefaultWorkspaceName from "components/App/hooks/useDefaultWorkspaceName";
import { Button } from "components/design-system/Button";
import {} from "components/design-system/FloatingUi/DropdownActions";
import Hr from "components/design-system/Hr";
import {
  ExpandableSection,
  Section,
  SectionBody,
  SectionHeader,
  SectionItem,
  SectionSkeleton,
} from "components/design-system/ui/sections-sidebar";
import { routeToGroup, useRouteParams } from "components/routing/utils";
import WorkspaceActionMenu from "components/workspace/WorkspaceActionMenu";
import {
  WorkspaceModal,
  WorkspaceModalJoin,
  WorkspaceModalTabs,
} from "components/workspace/WorkspaceModal";
import {
  WorkspacesAndGroupsListQuery,
  useWorkspacesAndGroupsListQuery,
} from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useLocalSettingsStore from "store/useLocalSettingsStore";
import useModalStore from "store/useModalStore";
import { formatGroupName } from "utils/group/formatGroupName";
import sumUnreadCount from "utils/sumUnreadCount";
import tw from "utils/tw";

import { SmallRecipientProfileItem } from "components/ProfileItem";

import AddGroupsButton from "components/views/inbox/InboxMain/InboxAddButtons/AddGroupsButton";
import GroupsListItem from "./GroupsListItem";

const maxGroups = 200;

type GroupOrWorkspaceEdge = GroupEdgeSimple | WorkspaceEdgeSimple;

type Props = {
  hideText?: boolean;
  skipAutoSelect?: boolean;
};

const WorkspacesList = ({ hideText, skipAutoSelect }: Props) => {
  const history = useHistory();
  const defaultWorkspaceName = useDefaultWorkspaceName();
  const { recipientID: selectedID, view } = useRouteParams();
  const [moreExpanded, setMoreExpanded] = useState<string[] | null>(null);
  const { authReady } = useAuthData();
  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));

  const { collapsedSidebarSections } = useLocalSettingsStore(
    ({ collapsedSidebarSections }) => ({
      collapsedSidebarSections,
    })
  );

  const maxShownGroups = 15;

  const { data } = useWorkspacesAndGroupsListQuery({
    fetchPolicy: authReady ? "cache-and-network" : "cache-only",
    nextFetchPolicy: "cache-first",
    variables: {
      groupsLimit:
        (moreExpanded?.length ?? 0) > 0 ? maxGroups : maxShownGroups * 2,
      recentGroupsLimit: maxShownGroups,
    },
  });

  useEffect(() => {
    if (
      !skipAutoSelect &&
      !!data?.groupsByWorkspace.edges[0] &&
      !selectedID &&
      !view
    ) {
      history.push(
        routeToGroup({
          groupID: data.groupsByWorkspace.edges[0].node.id,
          to: "canonical",
        })
      );
    }
  }, [data, history, selectedID, skipAutoSelect, view]);

  if (!data) {
    return (
      <SectionSkeleton divider={false} title="Groups">
        <SmallRecipientProfileItem
          recipient={{ __typename: "Group" } as GroupSimple}
        />
      </SectionSkeleton>
    );
  }

  const groupsByWorkspace = data?.groupsByWorkspace;

  const workspacesResponse: JSX.Element[] = [];
  const externalWorkspacesResponse: JSX.Element[] = [];

  const sectionItems = (
    collapsed: boolean,
    edges: (GroupOrWorkspaceEdge & {
      workspaceEdge?: WorkspacesAndGroupsListQuery["groupsByWorkspace"]["edges"][0]["node"]["workspaceEdge"];
    })[]
  ) => {
    if (collapsed) {
      edges = edges.filter(edge => edge.node.id === selectedID);
    }

    return edges.map(edge => {
      const isDefaultWorkspace = edge.__typename.startsWith("Workspace");

      return (
        <SectionItem
          key={edge.node.id}
          className={tw({
            "!h-52": !!edge.workspaceEdge,
            "justify-center": hideText,
          })}
          flipId={`workspaces-${edge.node.id}`}
          onClick={() =>
            history.push(routeToGroup({ groupID: edge.node.id }), {
              mobileRoot: true,
            })
          }
          selected={edge.node.id === selectedID}
        >
          <GroupsListItem
            badgeUrl={edge.workspaceEdge?.node.avatarURL ?? undefined}
            isRead={!edge.unreadThreadCounts.unseen}
            recipient={
              isDefaultWorkspace
                ? { ...edge.node, name: defaultWorkspaceName }
                : edge.node
            }
            secondaryText={edge.workspaceEdge?.node.name}
          />
        </SectionItem>
      );
    });
  };

  const filterAndSortGroups = (
    edge: WorkspacesAndGroupsListQuery["groupsByWorkspace"]["edges"][0]
  ) => {
    const unseenGroups = edge.node.unseenGroups.edges.slice().reverse();
    const recentGroups = sortBy(
      edge.node.recentGroups.edges,
      e => formatGroupName(e.node).name
    );

    // If unseen groups count are < 15, fill the remaining space with recent groups.
    const unseenGroupsCount = unseenGroups.length;
    const filler =
      unseenGroups.length < maxShownGroups
        ? recentGroups.slice(0, maxShownGroups - unseenGroupsCount)
        : [];
    const visibleGroups = [...unseenGroups, ...filler];

    const hiddenGroups = sortBy(
      edge.node.otherGroups.edges.filter(
        r => !filler.find(f => f.node.id === r.node.id)
      ),
      e => formatGroupName(e.node).name
    );

    return {
      allGroups: [...visibleGroups, ...hiddenGroups],
      hiddenGroups,
      visibleGroups,
    };
  };

  const userWorkspaces = groupsByWorkspace?.edges.filter(edge =>
    nodeIs(edge.node.workspaceEdge, ["WorkspaceEdge"])
  );

  const externalWorkspaces = groupsByWorkspace?.edges.filter(edge =>
    nodeIs(edge.node.workspaceEdge, ["WorkspacePreviewEdge"])
  );

  const isMoreExpanded = (id: string) => !!moreExpanded?.includes(id);

  const handleCollapseChange = (id: string) =>
    setMoreExpanded(
      isMoreExpanded(id)
        ? (moreExpanded?.filter(m => m !== id) ?? [])
        : [...(moreExpanded ?? []), id]
    );

  const sectionHeaderCommonProps = {
    className: tw({ "px-0 justify-center": hideText }),
    collapseButtonClassName: tw({ "!px-0": hideText }),
    grow: !hideText,
    titleClassName: tw({ "!m-0": hideText }),
  };

  const SectionHeaderCount = ({ count }: { count: number }) => (
    <span className="text-text-subtle text-xs font-semibold -mr-4">
      {count > 99 ? "99+" : count}
    </span>
  );

  userWorkspaces?.length
    ? userWorkspaces?.forEach((edge, i) => {
        const workspaceEdge = nodeAs(edge.node.workspaceEdge, [
          "WorkspaceEdge",
        ]);
        if (!workspaceEdge) return;

        const sectionID = workspaceEdge.node.id;
        const collapsed = collapsedSidebarSections.includes(sectionID ?? "");

        const { allGroups, hiddenGroups, visibleGroups } =
          filterAndSortGroups(edge);

        const onClickTitle = () =>
          openModal(
            <WorkspaceModal
              defaultTab={WorkspaceModalTabs.Groups}
              workspaceID={workspaceEdge.node.id}
            />,
            { id: workspaceEdge.node.id }
          );

        const headerTitle = (
          <WorkspaceActionMenu workspaceEdge={workspaceEdge} isSidebar />
        );

        const groupEdges = [workspaceEdge, ...visibleGroups];
        const groupItems = sectionItems(collapsed, groupEdges);

        const totalCount = allGroups.length;

        workspacesResponse.push(
          <Section
            key={workspaceEdge.node.id}
            divider={i === 0 ? false : undefined}
            header={
              <SectionHeader
                collapsed={collapsed}
                onClickTitle={onClickTitle}
                sectionKey={sectionID}
                title={!hideText ? headerTitle : ""}
                {...sectionHeaderCommonProps}
              >
                {hideText && collapsed && (
                  <SectionHeaderCount
                    count={
                      isMoreExpanded(workspaceEdge.node.id)
                        ? totalCount
                        : groupEdges.length
                    }
                  />
                )}
              </SectionHeader>
            }
          >
            <SectionBody
              collapsed={collapsed}
              flipKey={
                isMoreExpanded(workspaceEdge.node.id)
                  ? "more"
                  : groupItems.map(i => i.key).join()
              }
              moreCollapsed={!isMoreExpanded(workspaceEdge.node.id)}
              sectionItems={groupItems}
              selectedItem={
                !groupEdges.find(e => e.node.id === selectedID)
                  ? sectionItems(
                      collapsed,
                      hiddenGroups.filter(e => e.node.id === selectedID)
                    )[0]
                  : undefined
              }
            >
              <ExpandableSection
                addButton={
                  <AddGroupsButton workspaceID={workspaceEdge.node.id} />
                }
                hideButton={hideText}
                moreCollapsed={!isMoreExpanded(workspaceEdge.node.id)}
                moreItems={sectionItems(collapsed, hiddenGroups)}
                moreUnreadCount={sumUnreadCount(hiddenGroups, "unseen")}
                moreUnreadMentionCount={sumUnreadCount(
                  hiddenGroups,
                  "mentioned"
                )}
                onClickLess={() => handleCollapseChange(workspaceEdge.node.id)}
                onClickMore={() => handleCollapseChange(workspaceEdge.node.id)}
              />
            </SectionBody>
          </Section>
        );
      })
    : workspacesResponse.push(
        <>
          <Hr className="w-full mt-8" colorClassName="border-t-border-strong" />
          <Button
            buttonFont="normal"
            buttonStyle="subtle"
            className="mt-8 !pl-16"
            icon="Plus"
            onClick={() => openModal(<WorkspaceModalJoin />)}
          >
            Create workspace
          </Button>
        </>
      );

  const externalGroups = externalWorkspaces
    ?.flatMap(w => filterAndSortGroups(w).allGroups)
    .map(group => ({
      ...group,
      workspaceEdge: externalWorkspaces.find(
        w => w.node.workspaceEdge.node.id === group.node.workspaceID
      )?.node.workspaceEdge,
    }));

  if (externalGroups?.length) {
    const sectionKey = "External groups";
    const externalCollapsed = collapsedSidebarSections.includes(sectionKey);
    const groupEdges = externalGroups.slice(0, maxShownGroups);
    const groupItems = sectionItems(externalCollapsed, groupEdges);
    const moreGroupEdges = externalGroups.slice(maxShownGroups);
    const totalCount = groupEdges.length + moreGroupEdges.length;
    externalWorkspacesResponse.push(
      <Section
        key={sectionKey}
        header={
          <SectionHeader
            collapsed={externalCollapsed}
            sectionKey={sectionKey}
            title={!hideText ? sectionKey : ""}
            {...sectionHeaderCommonProps}
          >
            {hideText && externalCollapsed && (
              <SectionHeaderCount
                count={
                  isMoreExpanded(sectionKey) ? totalCount : groupEdges.length
                }
              />
            )}
          </SectionHeader>
        }
      >
        <SectionBody
          collapsed={externalCollapsed}
          flipKey={
            isMoreExpanded(sectionKey)
              ? "more"
              : groupItems.map(i => i.key).join()
          }
          moreCollapsed={!isMoreExpanded(sectionKey)}
          sectionItems={groupItems}
          selectedItem={
            !groupEdges.find(e => e.node.id === selectedID)
              ? sectionItems(
                  externalCollapsed,
                  moreGroupEdges.filter(e => e.node.id === selectedID)
                )[0]
              : undefined
          }
        >
          <ExpandableSection
            hideButton={hideText}
            moreCollapsed={!isMoreExpanded(sectionKey)}
            moreItems={sectionItems(externalCollapsed, moreGroupEdges)}
            moreUnreadCount={sumUnreadCount(moreGroupEdges)}
            moreUnreadMentionCount={sumUnreadCount(moreGroupEdges, "mentioned")}
            onClickLess={() => handleCollapseChange(sectionKey)}
            onClickMore={() => handleCollapseChange(sectionKey)}
          />
        </SectionBody>
      </Section>
    );
  }

  return (
    <>
      {workspacesResponse}
      {externalWorkspacesResponse}
    </>
  );
};

export default WorkspacesList;
