import { ThreadEdge } from "@utility-types";
import { Button } from "components/design-system/Button";
import { isSubscribed } from "components/threads-list/ThreadItem/utils";
import useInboxThreadActions from "hooks/thread/useInboxThreadActions";

const FollowButton = ({ threadEdge }: { threadEdge: ThreadEdge }) => {
  const following = isSubscribed(threadEdge);
  const threadActions = useInboxThreadActions();
  return (
    <Button
      buttonStyle="icon-secondary"
      buttonType="icon"
      className="size-28 justify-center rounded-md border-1 border-border-container hover:!bg-background-secondary"
      iconSize={20}
      icon={following ? "BellSmallFilled" : "BellSmall"}
      onClick={e => {
        e.stopPropagation();
        threadActions.toggleThreadSubscribed(threadEdge);
      }}
      tooltip={following ? "Unfollow thread" : "Follow thread"}
      tooltipPlacement="bottom"
      tooltipStyle="inverted"
    />
  );
};

export default FollowButton;
