import { animated, useTransition } from "@react-spring/web";
import { defaultSpring } from "./utils";

type Props = {
  className?: string;
  show: boolean;
};
const FadeInOut = ({ children, className, show }: WithChildren<Props>) => {
  const transitions = useTransition(show, {
    config: { ...defaultSpring },
    enter: { opacity: 1 },
    from: { opacity: 0 },
    leave: { opacity: 0 },
    reverse: show,
  });

  return transitions(
    (styles, item) =>
      item && (
        <animated.div className={className} style={styles}>
          {children}
        </animated.div>
      )
  );
};

export default FadeInOut;
