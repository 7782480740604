import { ThreadEdgeSimple } from "@utility-types";
import { Dropdown } from "components/design-system/FloatingUi";
import { DropdownActionButton } from "components/design-system/FloatingUi/DropdownActionButton";
import { Icon } from "components/design-system/icons";
import { useState } from "react";
import ThreadReminderMenu from "./ThreadReminderMenu";

const ThreadReminderDropdown = ({
  threadEdge,
}: { threadEdge: ThreadEdgeSimple }) => {
  const [open, setOpen] = useState(false);
  return (
    <Dropdown
      content={
        <ThreadReminderMenu
          setParentDropdownOpen={setOpen}
          threadEdge={threadEdge}
        />
      }
      placement="left-start"
      open={open}
      setOpen={setOpen}
    >
      <div>
        <DropdownActionButton icon="ClockAlarm">
          Remind me
          <div className="flew grow" />
          <Icon className="text-icon-secondary" icon="ChevronRight" size={20} />
        </DropdownActionButton>
      </div>
    </Dropdown>
  );
};

export default ThreadReminderDropdown;
