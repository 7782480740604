import { Recipient, Thread, User } from "@utility-types";
import { MessageEditor } from "components/MessageEditor";
import { HistoryState } from "components/Navigation/HistoryState";
import { Button } from "components/design-system/Button";
import { Form } from "components/design-system/Forms";
import {
  currentPathWithoutDrawer,
  routeToThread,
} from "components/routing/utils";
import { useValidateMessage } from "components/thread/hooks";
import { formToInput } from "components/threads/ThreadCompose/SendMessageReducer";
import ThreadComposeEditor from "components/threads/ThreadCompose/ThreadComposeEditor";
import ThreadPromptSuggestions from "components/threads/ThreadCompose/ThreadPromptSuggestions";
import { useSendMessage } from "components/threads/ThreadCompose/hooks";
import { DraftForm } from "components/threads/ThreadCompose/types";
import { Addable } from "generated/graphql";
import useReadOnlyEditorState from "hooks/editor/useReadOnlyEditorState";
import { useGlueAIBot } from "hooks/glueAI/useGlueAIBot";
import {
  ComponentProps,
  ElementRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router";
import tw from "utils/tw";
import AIThreadHeader from "./AIThreadHeader";

type Props = {
  glueAIBot?: User;
  isModal?: boolean;
  secondaryPane?: boolean;
} & Parameters<typeof useSendMessage>[0];

const AIComposeInner = ({
  glueAIBot,
  initialDraft,
  isModal,
  secondaryPane,
}: Props) => {
  const history = useHistory<HistoryState>();

  const editor = useRef<ElementRef<typeof MessageEditor> | null>(null);
  const formRef = useRef<HTMLFormElement | null>(null);
  const { validateMessageToGlueAI } = useValidateMessage();

  useReadOnlyEditorState(editor);

  const [_lastMention, setLastMention] = useState<Recipient>();

  const handleClose = useCallback(() => {
    if (secondaryPane) {
      history.push(currentPathWithoutDrawer());
    }
  }, [history, secondaryPane]);

  const onFinish = useCallback(
    (result?: Thread) => {
      if (!result) {
        handleClose();
        return;
      }

      history.replace(
        routeToThread({
          threadID: result.id,
          to: secondaryPane ? "secondary" : "primary",
        })
      );
    },
    [handleClose, history, secondaryPane]
  );

  const { compose, dispatch, sendDraft } = useSendMessage({
    initialDraft: {
      ...initialDraft,
      recipients: glueAIBot ? [glueAIBot] : [],
    },
    onFinish,
  });

  // Callbacks

  const onAddableChange = useCallback(
    (recipientsAddable: Addable) => {
      dispatch({ type: "change", draftForm: { recipientsAddable } });
    },
    [dispatch]
  );

  const onRecipientsChange = useCallback(
    (recipients: Recipient[]) => {
      dispatch({ type: "change", draftForm: { recipients } });
      setLastMention(undefined);
    },
    [dispatch]
  );

  const onSubjectChange = useCallback(
    (subject: string) => {
      dispatch({ type: "change", draftForm: { subject } });
    },
    [dispatch]
  );

  const onMessageChange = useCallback(() => {
    if (!editor.current) return;
    const { text, attachments } = editor.current.getMessage();
    const appUnfurlSetups = editor.current.getAppUnfurlSetups();
    dispatch({
      type: "change",
      draftForm: { message: { text, attachments }, appUnfurlSetups },
    });
  }, [dispatch]);

  // Disable submit button when pending or invalid content

  const readOnly = !!compose.pending;

  const submitDisabled = useMemo(
    () =>
      readOnly ||
      !validateMessageToGlueAI(
        formToInput({
          ...compose.draftForm,
        }),
        "send",
        false
      ),
    [compose.draftForm, readOnly, validateMessageToGlueAI]
  );

  useEffect(() => {
    editor.current?.setReadOnly(readOnly);
    editor.current?.setIsProcessing(submitDisabled);
  }, [readOnly, submitDisabled]);

  const defaultSubject = compose.draftForm?.subject ?? "";

  // const defaultRecipients =
  //   draft?.recipients ?? compose.draftForm.recipients ?? [];

  // const isGlueAI =
  //   compose.draftForm.recipients.length === 1 &&
  //   compose.draftForm.recipients?.some(r => r.id === glueAIBot?.id);

  return (
    <Form<DraftForm>
      formRef={formRef}
      useFormProps={{
        defaultValues: {
          subject: defaultSubject,
          recipients: [glueAIBot],
        },
      }}
      className={tw(
        "relative flex flex-col px-0 h-full w-full",
        "md:min-h-[50%] md:max-h-[100%]"
      )}
    >
      <AIThreadHeader
        compose={{
          ...compose,
          draftID: undefined,
        }}
        isModal={isModal}
        onAddableChange={onAddableChange}
        onRecipientsChange={onRecipientsChange}
        onSubjectChange={onSubjectChange}
        onClose={secondaryPane ? handleClose : undefined}
        readOnly={readOnly}
        secondaryPane={secondaryPane}
      />

      <ThreadComposeEditor
        accessory={
          !editor.current?.hasText() && (
            <ThreadPromptSuggestions
              onChoose={({ text, files }) => {
                editor.current?.setMessage({
                  text,
                  attachments: [],
                });
                if (files) {
                  editor.current?.addAttachments(files);
                }
              }}
            />
          )
        }
        autoFocus
        compose={{
          ...compose,
          draftID: undefined,
        }}
        editor={editor}
        onChange={onMessageChange}
        onMention={setLastMention}
        sendDraft={sendDraft}
        // variant={variant}
      />

      <div className="hidden md:flex flex-row items-center justify-end px-20 py-16 border-t-border-container select-none touch-none">
        <Button
          buttonStyle="primary"
          icon="Send"
          iconSize={20}
          type="submit"
          disabled={submitDisabled}
          onClick={sendDraft}
        >
          Send
        </Button>
      </div>
    </Form>
  );
};

const AICompose: React.FC<Props> = (
  props: ComponentProps<typeof AICompose>
) => {
  const glueAIBot = useGlueAIBot();

  // ensures the composer is never initialized without the glueAIBot user;
  // typically only an issue when the browser view is reloaded while the composer is open
  if (!glueAIBot) return null;

  return <AIComposeInner glueAIBot={glueAIBot} {...props} />;
};

export default AICompose;
