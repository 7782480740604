import { MutableRefObject, useCallback, useEffect, useState } from "react";

import { ContactPayload, Contacts } from "@capacitor-community/contacts";

import useComponentMounted from "hooks/useComponentMounted";
import { isNative } from "utils/platform";

export type Permission = boolean | undefined;
export type ContactFragment = Pick<ContactPayload, "name" | "emails">;

const cachedPermission: MutableRefObject<Permission> = {
  current: isNative() ? undefined : true,
};

export const useNativeContacts = (): {
  getContacts: () => Promise<ContactFragment[]>;
  getPermission: () => Promise<boolean>;
  permission: Permission;
} => {
  const isMounted = useComponentMounted();
  const [permission, setPermission] = useState<Permission>(
    cachedPermission.current
  );

  const getPermission = useCallback(() => {
    if (!isNative()) {
      return Promise.resolve(true);
    }

    return Contacts.requestPermissions().then(permissionStatus => {
      const granted = permissionStatus.contacts === "granted";
      cachedPermission.current = granted;

      if (isMounted.current) {
        setPermission(granted);
      }

      if (!granted) {
        alert(
          "You've already declined contacts permissions. " +
            "To re-enable, visit Settings > Privacy > Contacts and enable for Glue."
        );
      }

      return granted;
    });
  }, [isMounted]);

  const getContacts = useCallback((): Promise<ContactFragment[]> => {
    if (!isNative()) {
      return Promise.resolve([]);
    }

    return Contacts.getContacts({
      projection: {
        name: true,
        emails: true,
      },
    }).then(({ contacts }) => contacts);
  }, []);

  useEffect(() => {
    if (cachedPermission.current !== permission) {
      setPermission(cachedPermission.current);
    }

    if (cachedPermission.current !== undefined) {
      return;
    }

    Contacts.checkPermissions().then(permissionStatus => {
      const granted = permissionStatus.contacts === "granted";
      cachedPermission.current = granted;
      if (isMounted.current) {
        setPermission(granted);
      }
    });
  }, [isMounted, permission]);

  return {
    getContacts,
    getPermission,
    permission,
  };
};

export default useNativeContacts;
