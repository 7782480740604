import BlockComponent, { BlockComponentProps } from "./BlockComponent";
import BlockKitPlainText from "./BlockPlainText";
import { InputBlock } from "./blockTypes";

type BlockInputProps = BlockComponentProps & {
  block: InputBlock;
};

const BlockInput = ({ block, handlers }: BlockInputProps) => {
  return (
    <div className="py-4">
      <p className="block mb-2">
        <BlockKitPlainText text={block.label} />
      </p>
      <BlockComponent
        block={block.control}
        handlers={handlers}
        parentType={block.type}
      />
      {block.hint && (
        <p className="text-text-secondary mt-2 text-sm">
          <BlockKitPlainText text={block.hint} />
        </p>
      )}
    </div>
  );
};

export default BlockInput;
