import { useMemo } from "react";
import { matchPath, useLocation } from "react-router-dom";

import { pick } from "lodash-es";
import { z } from "zod";

import { FeedType as ActivityTabs } from "generated/graphql";
import useAppStateStore from "store/useAppStateStore";
import type { RoutesType, RoutesTypeStack } from "store/useRoutesStore";
import env from "utils/processEnv";

export const apiURL = (path: string): string => `${env.glueApiUrl}${path}`;

export const AppOrigin = env.glueAppUrl;

export const getAppURLObject = (input: string) => {
  let url: URL | undefined;

  try {
    url = new URL(input);
  } catch (_) {
    return null;
  }

  if (url.origin !== AppOrigin) return null;

  return url;
};

export enum DMTabs {
  Recent = "Recent",
  Directory = "Directory",
}

export enum GroupTabs {
  Threads = "Threads",
  Feed = "Feed",
  Chat = "Chat",
  Shared = "Shared",
}

export enum SearchTabs {
  Conversations = "Conversations",
  Groups = "Groups",
  People = "People",
}

export enum ThreadsTabs {
  Following = "Following",
  Starred = "Starred",
  Drafts = "Drafts",
  Sent = "Sent",
  Archived = "Archived",
  All = "All",
}

export { ActivityTabs };

export const superTabsDefaults = {
  activity: "/activity",
  ai: "/ai",
  dms: "/dms",
  feed: "/feed",
  groups: "/groups",
  inbox: "/",
  search: "/search",
  threads: "/threads",
};

export enum TabName {
  Inbox = "Inbox",
  AI = "Glue AI",
  Feed = "Feed",
  Groups = "Groups",
  DMs = "DMs",
  Activity = "Activity",
  Search = "Search",
}

export type SuperTabKeys = keyof typeof superTabsDefaults;

export const superTabNames: Record<TabName, SuperTabKeys> = {
  [TabName.Activity]: "activity",
  [TabName.AI]: "ai",
  [TabName.DMs]: "dms",
  [TabName.Feed]: "feed",
  [TabName.Groups]: "groups",
  [TabName.Inbox]: "inbox",
  [TabName.Search]: "search",
};

export type SuperTab =
  | "activity"
  | "ai"
  | "dms"
  | "feed"
  | "groups"
  | "inbox"
  | "search"
  | "threads";

export const defaultRoutesStack: RoutesTypeStack = Object.entries(
  superTabsDefaults
)
  .map(([k, v]) => ({
    [k]: [v === "/" ? "/inbox" : v],
  }))
  .reduce((acc, cur) => ({ ...acc, ...cur }), {});

export type AIViewsType = "compose";
export type InboxViewsType = "compose" | "requests";

type Views = AIViewsType | InboxViewsType;

export type PathnameParams = {
  superTab?: SuperTab;
  threadID?: string;
  messageID?: string;
  recipientID?: string;
  userID?: string;
  joinID?: string;
  view?: Views;
  appID?: string;
};

/*
 *  i is used for "Invite links", it is not on this list because we don't need to navigate to it and its only purpose is to handle the invite link flow
 *  do not use it for anything else
 */
export type SearchParams = {
  d?: string; // Drawer
  t?: string; // Tab
  v?: string; // View
};

export type PaneDeclaration = "canonical" | "primary" | "secondary";
export type RouteParams = PathnameParams & SearchParams;
export type SearchIds = { messageID?: string; threadID?: string };

// Only valid superTabs for thread in primary pane,
// otherwise route to canonical thread path.
const primaryThreadTabs: Set<SuperTab> = new Set([
  "activity",
  "ai",
  "inbox",
  "search",
  "threads",
]);

const match = {
  superTab:
    "/:superTab(activity|ai|dms|feed|groups|inbox|search|threads)" as const,
  threadID: "/:threadID(dft_\\w+|thr_\\w+)" as const,
  messageID: "/:messageID(msg_[\\w]+|[\\w-]+-[\\w]+)" as const, // match either Glue or Stream IDs
  recipientID: "/:recipientID(grp_\\w+|wks_\\w+)" as const, // TODO: rename to groupID
  userID: "/:userID(usr_\\w+)" as const,
  joinID: "/:joinID(joi_\\w+)" as const,
  appID: "/:appID(app_\\w+)" as const,
  view: "/:view(compose|requests)" as const,
} satisfies { [T in keyof PathnameParams]: `/:${T}(${string})` };

const optional = {
  profile: `${match.recipientID}?${match.userID}?` as const,
  thread: `${match.threadID}?${match.messageID}?` as const,
};

const canonical = {
  dms: `${match.userID}${match.messageID}?` as const,
  group: `${match.recipientID}` as const,
  thread: `/:threadID(thr_\\w+)${match.messageID}?` as const,
};

export const PathMatch = {
  // Inbox super-tab
  inbox: {
    exact: true,
    path: `/:superTab(inbox)${optional.profile}${optional.thread}${match.appID}?` as const,
  },
  inboxViews: {
    exact: true,
    path: `/:superTab(inbox)${match.view}` as const,
  },
  threads: {
    // canonical for threadID
    exact: true,
    path: `/:superTab(threads)?${canonical.thread}` as const,
  },
  threadsHome: {
    exact: true,
    path: "/:superTab(threads)" as const,
  },

  // AI super-tab
  ai: {
    exact: true,
    path: `/:superTab(ai)${optional.thread}` as const,
  },
  aiViews: {
    exact: true,
    path: `/:superTab(ai)${match.view}` as const,
  },

  // Feed super-tab
  feed: {
    exact: true,
    path: `/:superTab(feed)${optional.thread}` as const,
  },

  // Groups super-tab
  groups: {
    // canonical for groupID
    exact: true,
    path: `/:superTab(groups|explore)?${canonical.group}${optional.thread}` as const,
  },
  groupsExplore: {
    exact: true,
    path: `/:superTab(groups|explore)${optional.thread}` as const,
  },
  groupsViews: {
    exact: true,
    path: `/:superTab(groups|explore)${match.view}` as const,
  },

  // DMs super-tab
  dms: {
    // canonical for userID
    exact: true,
    path: `/:superTab(dms)?${canonical.dms}` as const,
  },
  dmsHome: {
    exact: true,
    path: "/:superTab(dms)" as const,
  },

  // Activity super-tab
  activity: {
    exact: true,
    path: `/:superTab(activity)${optional.profile}${optional.thread}${match.joinID}?` as const,
  },

  // Non-tab routes

  search: {
    exact: true,
    path: `/:superTab(search)${optional.thread}` as const,
  },

  // The hierarchical set of all possible routes in the app, from super-tab down to message ID
  // It should be possible to match any route in the app, though it won't validate that a route is correct.
  // Note: thread must come last, so that messageID can be last following a profile DM or chat.
  all: {
    path: `${match.superTab}?${match.view}?${optional.profile}${optional.thread}${match.joinID}?${match.appID}?` as const,
  },

  home: { exact: true, path: "/" },
};

export const drawerParams = (d: string) => {
  const isApp = d?.match(/^app_/);
  const isGroup = d?.match(/^grp_/) || d?.match(/^wks_/);
  const isJoin = d?.match(/^joi_/);
  const isUser = d?.match(/^usr_/);

  if (isApp) {
    return { appID: d };
  }

  if (isGroup) {
    return { groupID: d };
  }

  if (isJoin) {
    return { joinID: d };
  }

  if (isUser) {
    return { userID: d };
  }

  return {
    messageID: d?.split("/")[1],
    threadID: d?.split("/")[0],
  };
};

/**
 * @returns Record<string, string> of search params
 * Converts the location search params to an object
 */
const dRegex = /app_\w+|dft_\w+|grp_\w+|wks_\w+|joi_\w+|thr_\w+|usr_\w+/;
const vRegex = /^compose$|^compose-dm$|^directory$|^requests$/;

const searchParamsSchema = z.object({
  d: z.string().regex(dRegex).optional(),
  t: z
    .enum([
      ActivityTabs.All,
      ActivityTabs.Mentions,
      ActivityTabs.Reactions,
      ActivityTabs.Groups,
      ActivityTabs.Requests,
      DMTabs.Directory,
      DMTabs.Recent,
      GroupTabs.Chat,
      GroupTabs.Feed,
      GroupTabs.Threads,
      GroupTabs.Shared,
      SearchTabs.Conversations,
      SearchTabs.Groups,
      SearchTabs.People,
      ThreadsTabs.All,
      ThreadsTabs.Archived,
      ThreadsTabs.Drafts,
      ThreadsTabs.Following,
      ThreadsTabs.Sent,
      ThreadsTabs.Starred,
    ])
    .optional(),
  v: z.string().regex(vRegex).optional(),
});

export const searchParams = (location: { search: string }): SearchParams => {
  const search = new URLSearchParams(location.search);
  const result = searchParamsSchema.safeParse({
    d: search.get("d") ?? undefined,
    t: search.get("t") ?? undefined,
    v: search.get("v") ?? undefined,
  });

  if (!result.success) {
    console.warn(result.error);
    return {};
  }

  return result.data;
};

/**
 * @returns stringified search params
 * Converts the search params object to a string; can be used to update the location
 */
export const searchParamsToString = (search: SearchParams) =>
  new URLSearchParams(
    Object.entries(search)
      .filter(([_, v]) => v !== undefined)
      .map(([k, v]) => [k, v.toString()])
  ).toString();

/**
 * @returns an array of tuples, formatted as `[threadID, messageID]`
 * parses separate thread and message IDs from the ?d search param
 */
export const parseIdsFromSearch = (location: { search: string }) => {
  const dParams: string[] = searchParams(location).d?.split(",") ?? [];
  const ids = dParams?.map(threadPath =>
    matchPath<SearchIds>(`/${threadPath}`, {
      path: `${match.threadID}${match.messageID}?`,
    })
  );
  return ids;
};

/**
 * @returns a "location path" format string given the provided params
 */
export const routePath = (params: RouteParams) => {
  const search = searchParamsToString({
    d: params.d,
    t: params.t,
    v: params.v,
  });

  return `/${[
    params.superTab,
    params.recipientID || params.view,
    params.threadID || params.userID || params.joinID || params.appID,
    params.messageID,
  ]
    .filter(Boolean)
    .join("/")}${search ? `?${search}` : ""}`;
};

export const routeURL = (params: RouteParams): string =>
  `${AppOrigin}${routePath(params)}`;

/**
 * @returns a "location path" format string given the provided params, overriding the tab only
 */
export const tabPath = <T extends string | undefined>(
  t: T,
  extra?: RouteParams
) => {
  const nextParams = routeParams(window.location);
  nextParams.t = t;
  delete nextParams.view;
  delete nextParams.threadID;
  delete nextParams.messageID;
  if (extra?.superTab && extra.superTab !== nextParams.superTab) {
    delete nextParams.recipientID;
  }
  return routePath({ ...nextParams, ...extra });
};

/**
 * @returns a "location path" format string that corresponds to a path to a group
 */
export const groupPath = <T extends string | undefined>(
  recipientID: string,
  threadID?: string,
  t?: T
) => routePath({ recipientID, t, threadID });

/**
 * @returns a "location path" format string that corresponds to a path to a user
 */
export const userPath = (threadID?: string, userID?: string) => {
  const nextParams = routeParams(window.location);
  return routePath({
    recipientID: userID,
    t: nextParams.t ?? DMTabs.Recent,
    threadID,
  });
};

/**
 * @returns a string with the location merged with searchParams
 */
export const pathWithSearch = (
  location: { pathname: string; search: string },
  params: SearchParams
) => {
  return `${location.pathname}?${searchParamsToString({
    ...searchParams(location),
    ...params,
  })}`;
};

/**
 * @returns a string with the current location followed by searchParams
 */
export const currentPathWithSearch = (params: SearchParams): string => {
  return pathWithSearch(window.location, params);
};

export const currentPathWithoutDrawer = () => {
  return currentPathWithSearch({ d: undefined });
};

/**
 * breaks the location into pathname and search params
 * @returns an object of the route's pathname and search params
 */
export const routeParams = (location: { pathname: string; search: string }) => {
  const params = matchPath<PathnameParams>(
    location.pathname,
    PathMatch.all
  )?.params;
  // Home path is the only one that must have superTab set
  // for all other paths underneath it. The rest have canonical
  // resources at the top level. This ensures all inbox routes
  // have the proper prefix.
  if (params && location.pathname === "/") {
    params.superTab = "inbox";
  }

  return {
    ...params,
    ...searchParams(location),
  };
};

/**
 * @input a route string, e.g. `/grp_001/thr_123?d=thr_456`;
 * @returns an object of the route's pathname and search params
 */
export const locationFromRoute = (route: string) => {
  return new URL(route, window.location.origin);
};

export const RouteToApp = (options: {
  appID: string;
  to?: "primary" | "secondary";
}) => {
  const nextParams = routeParams(window.location);

  if (options.to === "secondary") {
    nextParams.d = options.appID;
    nextParams.superTab = "inbox";
    return routePath(nextParams);
  }

  return routePath({
    superTab: "inbox",
    threadID: options.appID,
    d: nextParams.d,
  });
};

export const routeToJoinRequest = (options: {
  joinID: string;
  to?: "secondary";
}) => {
  const nextParams = routeParams(window.location);

  if (options.to === "secondary") {
    nextParams.d = options.joinID;
    nextParams.superTab = "activity";
    return routePath(nextParams);
  }

  return routePath({
    superTab: "activity",
    d: nextParams.d,
    t: nextParams.t,
    joinID: options.joinID,
  });
};

export const routeToGroup = (options: {
  groupID: string;
  to?: "canonical" | "secondary";
}) => {
  let nextParams = routeParams(window.location);

  if (options.to === "secondary") {
    nextParams.d = options.groupID;
    return routePath(nextParams);
  }

  delete nextParams.v;
  delete nextParams.view;

  // Only valid superTabs are "activity" and "groups"
  if (
    nextParams.superTab !== "activity" &&
    nextParams.superTab !== "groups" &&
    nextParams.superTab !== "inbox" &&
    !nextParams.recipientID
  ) {
    options.to = "canonical";
  }

  if (options.to === "canonical") {
    nextParams = { t: nextParams.t };
  }

  nextParams.recipientID = options.groupID;
  nextParams.threadID = undefined; // Groups don't have nested threads
  nextParams.messageID = undefined;

  if (nextParams.superTab === "activity") {
    nextParams.joinID = undefined;
    return routePath(nextParams);
  }

  return routePath(nextParams);
};

const routeToThreadMobile = (options: {
  messageID?: string;
  recipientID?: string;
  superTab?: Extract<
    SuperTab,
    "activity" | "ai" | "groups" | "inbox" | "search" | "threads"
  >;
  threadID: string;
  to?: PaneDeclaration;
}) => {
  const nextParams = routeParams(window.location);

  if (options.superTab) {
    nextParams.recipientID = undefined;
    nextParams.superTab = options.superTab;
  }

  nextParams.t = undefined;
  nextParams.v = undefined;
  nextParams.view = undefined;
  nextParams.threadID = options.threadID;
  nextParams.messageID = options.messageID;

  return routePath(nextParams);
};

export const routeToThread = (options: {
  messageID?: string;
  recipientID?: string;
  superTab?: Extract<
    SuperTab,
    "activity" | "ai" | "groups" | "inbox" | "search" | "threads"
  >;
  threadID: string;
  to?: PaneDeclaration; // if pane invalid, uses canonical
  forceTo?: PaneDeclaration; // force pane for ambiguous cases
}) => {
  const { breakpointMD } = useAppStateStore.getState();
  if (!breakpointMD) {
    return routeToThreadMobile(options);
  }

  let nextParams = routeParams(window.location);
  if (options.superTab) {
    if (options.to !== "secondary") {
      nextParams.recipientID = undefined;
    }
    nextParams.superTab = options.superTab;
  }

  // Default to primary pane or canonical route if no superTab specified
  const defaultPane =
    nextParams.recipientID || nextParams.superTab ? "primary" : "canonical";
  let threadPane = options.forceTo ?? options.to ?? defaultPane;

  // Route to secondary pane when possible and canonical not specified
  if (
    threadPane === "primary" &&
    (nextParams.recipientID || //supports Groups tab routing, e.g. `/grp_.../thr_...`
      (nextParams.superTab && !primaryThreadTabs.has(nextParams.superTab)))
  ) {
    // If not valid superTab for primary pane, route to secondary
    threadPane = options.forceTo ?? "secondary";
  }

  // Open to secondary pane
  if (threadPane === "secondary") {
    nextParams.d = [options.threadID, options.messageID]
      .filter(Boolean)
      .join("/");
    return routePath(nextParams);
  }

  // keeping this after the secondary check;
  // only remove the view if `canonical` or `primary`
  delete nextParams.view;

  if (threadPane === "canonical") {
    nextParams = { t: nextParams.t };
  }

  nextParams.threadID = options.threadID;
  nextParams.messageID = options.messageID;
  nextParams.joinID = undefined;
  nextParams.v = undefined;

  return routePath(nextParams);
};

export const routeToUser = (options: {
  to: PaneDeclaration;
  userID: string;
}) => {
  let nextParams = routeParams(window.location);

  if (options.to === "secondary") {
    nextParams.d = options.userID;
    return routePath(nextParams);
  }

  // keeping this after the secondary check;
  // only delete the view if opening to `canonical` or `primary`
  delete nextParams.view;

  // Only valid superTabs are "activity", "inbox", and "dms", otherwise route to canonical
  if (!["activity", "inbox", "dms"].find(t => nextParams.superTab === t)) {
    options.to = "canonical";
  }

  if (options.to === "canonical") {
    nextParams = { t: nextParams.t };
  }

  nextParams.threadID = options.userID;
  nextParams.joinID = undefined;

  return routePath(nextParams);
};

const viewRouteParams: (keyof RouteParams)[] = ["d", "superTab", "view"];

export const routeToView = (options: {
  d?: string | null; // use `null` to clear the drawer
  superTab?: SuperTab;
  view: Views;
}) => {
  const nextParams = pick(routeParams(window.location), viewRouteParams);

  if (options.d) {
    nextParams.d = options.d;
  }

  if (options.superTab) {
    nextParams.superTab = options.superTab;
  }

  nextParams.view = options.view;

  return routePath(nextParams);
};

export const useRouteParams = (): RouteParams & {
  location: { pathname: string; search: string };
} => {
  const location = useLocation();
  return useMemo(() => ({ location, ...routeParams(location) }), [location]);
};

export const useRoutePartition = () => {
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));
  const location = useLocation();
  const superTab = useMemo(() => {
    let { superTab } = routeParams(location);

    if (!breakpointMD && superTab === "threads") {
      // on mobile, threads is nested under inbox
      superTab = "inbox";
    }

    if (!superTab) {
      if (matchPath<RouteParams>(location.pathname, PathMatch.threads)) {
        // canonical for threadID
        superTab = "threads";
      }
      if (matchPath<RouteParams>(location.pathname, PathMatch.groups)) {
        // canonical for groupID
        superTab = "groups";
      }
      if (matchPath<RouteParams>(location.pathname, PathMatch.dms)) {
        // canonical for userID
        superTab = "dms";
      }
      if (matchPath<RouteParams>(location.pathname, PathMatch.activity)) {
        superTab = "activity";
      }
    }
    return superTab;
  }, [location, breakpointMD]);

  return { superTab };
};

const superTabMatches: Record<
  keyof typeof superTabsDefaults,
  Parameters<typeof matchPath>[1][]
> = {
  activity: [PathMatch.activity],
  ai: [PathMatch.ai, PathMatch.aiViews],
  dms: [PathMatch.dms, PathMatch.dmsHome],
  feed: [PathMatch.feed],
  groups: [PathMatch.groups, PathMatch.groupsExplore, PathMatch.groupsViews],
  inbox: useAppStateStore.getState().breakpointMD
    ? [PathMatch.inbox, PathMatch.inboxViews]
    : [
        PathMatch.inbox,
        PathMatch.inboxViews,
        PathMatch.threads,
        PathMatch.threadsHome,
      ],
  search: [PathMatch.search],
  threads: [PathMatch.threads, PathMatch.threadsHome],
};

export const useValidatedTabRoute = (
  routes: RoutesType,
  superTab: keyof typeof superTabsDefaults
) => {
  const defaultRoute = superTabsDefaults[superTab];

  let nextRoute = routes[superTab];

  const nextPath = locationFromRoute(nextRoute).pathname;
  if (!superTabMatches[superTab].find(match => matchPath(nextPath, match))) {
    nextRoute = defaultRoute;
  }

  // Retain the drawer state between super tab routes
  return pathWithSearch(locationFromRoute(nextRoute), {
    d: searchParams(window.location).d,
  });
};

export const useValidatedMobileTabRoute = (
  routes: RoutesTypeStack,
  superTab: keyof typeof superTabsDefaults
) => {
  const defaultRoute = superTabsDefaults[superTab];
  const stack = routes[superTab] ?? [];

  let nextRoute = stack[stack.length - 1] ?? defaultRoute;

  const nextPath = locationFromRoute(nextRoute).pathname;
  if (!superTabMatches[superTab].find(match => matchPath(nextPath, match))) {
    nextRoute = defaultRoute;
  }

  return nextRoute;
};
