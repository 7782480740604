import { parse as parseExif } from "exifr";

type ImageDimensions = {
  height: number;
  width: number;
};

// copied from exifr/index.d.ts
interface Tags {
  [name: string]: string | number | number[] | Uint8Array;
}

const getResolutionUnit: (tags: Tags) => number = (tags: Tags) => {
  if (tags?.ResolutionUnit && typeof tags.ResolutionUnit === "number") {
    return tags.ResolutionUnit;
  }

  // 72 pixels per inch is the standard for online use;
  // we can make the imperfect assumption that the image is a screenshot if the resolution is divisible by 72
  const xRes = Number.parseInt(tags?.XResolution?.toString() ?? "72");
  const isScreenRes = xRes % 72 === 0;

  let resolutionUnit =
    isScreenRes || tags?.UserComment === "Screenshot" // Apple includes a UserComment tag for screenshots on iOS and macOS
      ? xRes / 72
      : 1;

  if (resolutionUnit < 1) resolutionUnit = 1;

  return resolutionUnit;
};

const handleOrientation = (
  tags: Tags,
  { width = 0, height = 0 }: { width?: number; height?: number }
) => {
  // Orientation values 1-4 are for landscape orientation, 5-8 for portrait orientation;
  // if undefined, we default to landscape orientation (e.g. height and width values should NOT be swapped)
  const isRotated = Number.parseInt(tags?.Orientation?.toString() ?? "1") >= 5;
  return {
    height: isRotated ? width : height,
    width: isRotated ? height : width,
  };
};

const newImage = (src: File) => {
  const img = new Image();
  img.crossOrigin = "Anonymous";
  img.src = URL.createObjectURL(src);
  return img;
};

const getImageDimensions: (src: File) => Promise<ImageDimensions | undefined> =
  async src =>
    parseExif(src, { skip: [], translateValues: false, xmp: true })
      .then(async tags => {
        // Hipstamatic doesn't have the correct tags; use the other promise;
        // last checked against Hipstamatic v10.3
        const skipTags = tags?.Make === "Hipstamatic";

        const promises = [
          // use the first available image height and width tag from metadata
          new Promise<{ height: number; width: number }>(resolve => {
            if (skipTags) return;

            const exif = {
              height:
                tags?.ExifImageHeight ??
                tags?.ImageHeight ??
                tags?.PixelYDimension,
              width:
                tags?.ExifImageWidth ??
                tags?.ImageWidth ??
                tags?.PixelXDimension,
            };

            if (exif.height && exif.width) {
              resolve({
                height: exif.height,
                width: exif.width,
              });
            }
          }),

          // use the actual image height and width if the metadata is missing
          new Promise<{ height: number; width: number }>(resolve => {
            const img = newImage(src);
            img.onload = () => {
              resolve({
                height: img.height,
                width: img.width,
              });
            };
          }),
        ];

        return Promise.race(promises).then(({ height: h, width: w }) => {
          const { height, width } = handleOrientation(tags, {
            height: h,
            width: w,
          });

          const resolutionUnit = getResolutionUnit(tags);

          return {
            height: Math.floor(height / resolutionUnit),
            width: Math.floor(width / resolutionUnit),
          };
        });
      })
      .catch(e => {
        console.error("Error loading image metadata", e);

        // in cases where exifr cannot recognize the file type (e.g. webp), try to get dimensions from the file
        return new Promise<ImageDimensions>(resolve => {
          const img = newImage(src);
          img.onload = () => {
            resolve({
              height: img.height,
              width: img.width,
            });
          };
        });
      });

export default getImageDimensions;
