/* spellchecker: disable */
import { ThreadEdgeSimple, UserEdge } from "@utility-types";
import { Addable, MemberRole, ThreadSubscription } from "generated/graphql";
import { workspaceAppEdge } from "mocks/workspaceAppEdge";
import { AuthData } from "providers/AuthProvider/hooks/useFetchAuthData";

type MockChatEdge = (
  recipient: UserEdge,
  authData: AuthData
) => ThreadEdgeSimple;

export const mockChatID = "thr_chat";

export const isMockChatEdge = (threadEdge: ThreadEdgeSimple) =>
  threadEdge.node.id === mockChatID;

export const mockChatEdge: MockChatEdge = (
  recipient: UserEdge,
  authData: AuthData
) => ({
  __typename: "ThreadEdge",
  cursor: "",
  id: recipient.id,
  isArchived: true,
  isMentioned: false,
  isRead: true,
  isSeen: true,
  isStarred: false,
  lastReadID: null,
  lastSeenID: null,
  remindAt: null,
  node: {
    __typename: "Thread",
    apps: { ...workspaceAppEdge },
    createdAt: "",
    firstMessage: null,
    id: mockChatID,
    isPersistentChat: true,
    lastMessage: null,
    messages: {
      __typename: "MessageConnection",
      replyCount: 0,
    },
    recentMessages: {
      __typename: "MessageConnection",
      edges: [],
      replyCount: 0,
    },
    recentMessagesUsers: [],
    recipients: {
      __typename: "ThreadRecipientConnection",
      edges: [
        {
          __typename: "ThreadRecipientEdge",
          node: {
            ...authData?.me,
          },
        },
        {
          __typename: "ThreadRecipientEdge",
          node: {
            ...recipient?.node,
          },
        },
      ],
      pageInfo: {
        __typename: "PageInfo",
        endCursor: "",
        hasNextPage: false,
      },
    },

    recipientsAddable: Addable.None,
    replyToMessage: null,
    subject: "",
    users: {
      __typename: "UserConnection",
      edges: [],
      totalCount: 0,
    },
  },
  recipientRole: MemberRole.Default,
  subscription: ThreadSubscription.Inbox,
  unreadMessageCounts: {
    __typename: "UnreadCounts",
    mentioned: 0,
    total: 0,
    unseen: 0,
  },
});
